/* tslint:disable */
/* eslint-disable */
/**
 * Payments API
 * # Introduction Manages onboarding of vendors and processing of payments  # Authentication The Payments API uses the OAuth2 client credentials grant to authorize service-to-service requests on the backend.  The caller should have only one application client that requests tokens for different audiences. Each API or audience that app client wishes to request tokens for requires an authorization in shared services IaC for Auth0.  If your application has not received Auth0 client credentials please refer to Architecture  Scopes are configured on client setup and all tokens requested by the client will have all scopes the client is entitled to.  The Token URL will change per environment.  - Dev - `https://dev.api.linqengineering.com/accounts/oauth/token`  - UAT - `https://uat.api.linqengineering.com/accounts/oauth/token`  - Production - `https://api.linqengineering.com/accounts/oauth/token`  In addition to your client credentials you also need to supply an audience value based on the environment. <br /> Valid Audiences include:      - Dev - `https://dev.api.linqengineering.com/payments`    - UAT - `https://uat.api.linqengineering.com/payments`    - Production - `https://api.linqengineering.com/payments`  Example Postman request to accounts API token endpoint:  ``` POST https://{{apiDomain}}.linqengineering.com/accounts/oauth/token ... raw -> JSON Body  {   \"clientId\": \"{{clientId}}\",   \"clientSecret\": \"{{clientSecret}}\",   \"audience\": \"https://{{apiDomain}}.linqengineering.com/payments\" } ```  See the Shared Services Postman collection for additional working examples. The returned access token should be added as a `Bearer` token authorization header.  API requests without an authorization header will fail.   <SecurityDefinitions />
 *
 * The version of the OpenAPI document: 0.0.30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import globalAxios from 'axios';
export const BASE_PATH = "https://services.linq.com/payments".replace(/\/+$/, "");
/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ",",
  ssv: " ",
  tsv: "\t",
  pipes: "|"
};
/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  constructor(configuration, basePath = BASE_PATH, axios = globalAxios) {
    var _a;
    this.basePath = basePath;
    this.axios = axios;
    if (configuration) {
      this.configuration = configuration;
      this.basePath = (_a = configuration.basePath) !== null && _a !== void 0 ? _a : basePath;
    }
  }
}
;
/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  constructor(field, msg) {
    super(msg);
    this.field = field;
    this.name = "RequiredError";
  }
}
/**
 *
 * @export
 */
export const operationServerMap = {};