export const Fields = {
  username: 'username',
  website: 'entities[0].website',
  routing: 'routing',
};

// 1 to 1 mapping
export const FieldToFormlyField = {
  [Fields.username]: 'contact.account.username',
  [Fields.website]: 'business.website',
  [Fields.routing]: 'routingNumber',
};

/**
 * Transforms Payment Provider (Payrix) field to Merchant Application field
 * @param field
 * @returns
 */
export const getFormlyFieldKey = (field: string) => {
  if (FieldToFormlyField[field]) {
    return FieldToFormlyField[field];
  }

  // Payrix uses bracket notation for arrays, whereas Angular Forms uses dot notation
  field = field.replaceAll('[', '.');
  field = field.replaceAll(']', '');

  if (field.includes('entities.0.accounts')) {
    field = field.replace('entities.0.accounts', 'banking.banks');
    field = field.replace('.account', '');
  }

  field = field
    .split('.')
    .map((partition) => FieldToFormlyField[partition] || partition)
    .join('.');

  return field;
};
