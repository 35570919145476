import { Component, inject, OnInit } from '@angular/core';
import { take, timer } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-submitted',
  standalone: true,
  imports: [],
  templateUrl: './submitted.component.html',
  styleUrls: ['./submitted.component.scss'],
})
export class SubmittedComponent implements OnInit {
  redirectTimeMs = 3000;

  $redirect = timer(this.redirectTimeMs).pipe(take(1));
  document = inject(DOCUMENT);

  ngOnInit() {
    this.$redirect.subscribe(() => {
      this.document.location.href = environment.portalUrl;
    });
  }
}
