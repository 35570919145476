/* eslint-disable @typescript-eslint/no-explicit-any */
import { AbstractControl } from '@angular/forms';
import { PasswordMinLength } from '@app/constants';
import { getFieldName } from '@linq/formly';

export const passwordValidator = (control: AbstractControl) => {
  const value = control.value;
  if (!value) {
    return null;
  }
  if (value.length > 1) {
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumber = /[0-9]/.test(value);
    const hasMinimumLength = value.length >= PasswordMinLength;
    if (!hasUpperCase || !hasLowerCase || !hasNumber || !hasMinimumLength) {
      return { password: true };
    }
  }
  return null;
};
export const passwordMessage = (err: boolean, field: any) => {
  const fieldName = getFieldName(field);
  return `${fieldName} does not meet requirements`;
};

export const validators = [
  {
    name: 'password',
    validation: passwordValidator,
  },
];

export const validationMessages = [{ name: 'password', message: passwordMessage }];
