import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import * as i0 from '@angular/core';
import { EventEmitter, Component, Input, Output, ViewEncapsulation, Optional, HostBinding, HostListener, NgModule, inject, DestroyRef, ViewChild, ViewChildren } from '@angular/core';
import * as i1$2 from '@angular/forms';
import { FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import * as i2$1 from '@angular/material/button';
import { MatButtonModule } from '@angular/material/button';
import * as i1$3 from '@angular/material/card';
import { MatCardModule } from '@angular/material/card';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import * as i2$3 from '@angular/material/expansion';
import { MatExpansionPanel, MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import * as i4$1 from '@angular/material/form-field';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import * as i2 from '@angular/material/icon';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import * as i4 from '@angular/material/input';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import * as i4$2 from '@angular/material/stepper';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import * as i3 from '@angular/material/tooltip';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import * as i1$1 from '@ngx-formly/core';
import { FieldType, FieldArrayType, FieldWrapper, FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';
import { FieldType as FieldType$1, FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import CreditCardValidator from 'card-validator';
const _c0 = a0 => ({
  fields: a0,
  level: 1
});
const _c1 = (a0, a1) => ({
  fields: a0,
  level: a1
});
function LinqFormlyValidationMessagesComponent_ng_template_0_For_1_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.reset(), " ");
  }
}
function LinqFormlyValidationMessagesComponent_ng_template_0_For_1_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const validationMessage_r2 = i0.ɵɵreference(2);
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.onValidationMessage(validationMessage_r2), " ");
  }
}
function LinqFormlyValidationMessagesComponent_ng_template_0_For_1_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function LinqFormlyValidationMessagesComponent_ng_template_0_For_1_Conditional_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.onUpdate(), " ");
  }
}
function LinqFormlyValidationMessagesComponent_ng_template_0_For_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, LinqFormlyValidationMessagesComponent_ng_template_0_For_1_Conditional_0_Template, 1, 1);
    i0.ɵɵelement(1, "formly-validation-message", 3, 1);
    i0.ɵɵtemplate(3, LinqFormlyValidationMessagesComponent_ng_template_0_For_1_Conditional_3_Template, 1, 1)(4, LinqFormlyValidationMessagesComponent_ng_template_0_For_1_ng_container_4_Template, 1, 0, "ng-container", 2)(5, LinqFormlyValidationMessagesComponent_ng_template_0_For_1_Conditional_5_Template, 1, 1);
  }
  if (rf & 2) {
    const field_r3 = ctx.$implicit;
    const ɵ$index_3_r4 = ctx.$index;
    const ɵ$count_3_r5 = ctx.$count;
    const validationMessage_r2 = i0.ɵɵreference(2);
    const level_r6 = i0.ɵɵnextContext().level;
    i0.ɵɵnextContext();
    const errorList_r7 = i0.ɵɵreference(1);
    i0.ɵɵconditional(ɵ$index_3_r4 === 0 && level_r6 === 1 ? 0 : -1);
    i0.ɵɵadvance();
    i0.ɵɵstyleProp("display", "none");
    i0.ɵɵproperty("field", field_r3);
    i0.ɵɵadvance(2);
    i0.ɵɵconditional(validationMessage_r2.errorMessage ? 3 : -1);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", errorList_r7)("ngTemplateOutletContext", i0.ɵɵpureFunction2(8, _c1, field_r3.fieldGroup, level_r6 + 1));
    i0.ɵɵadvance();
    i0.ɵɵconditional(ɵ$index_3_r4 === ɵ$count_3_r5 - 1 && level_r6 === 1 ? 5 : -1);
  }
}
function LinqFormlyValidationMessagesComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵrepeaterCreate(0, LinqFormlyValidationMessagesComponent_ng_template_0_For_1_Template, 6, 11, null, null, i0.ɵɵrepeaterTrackByIdentity);
  }
  if (rf & 2) {
    const fields_r8 = ctx.fields;
    i0.ɵɵrepeater(fields_r8);
  }
}
function LinqFormlyValidationMessagesComponent_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
const _c2 = (a0, a1, a2) => ({
  "linq-file-upload-target-wrapper--error": a0,
  "linq-file-upload-target-wrapper--active": a1,
  "linq-file-upload-target-wrapper--disabled": a2
});
const _c3 = a0 => ({
  "linq-file-upload-target--error": a0
});
function LinqFileUploadComponent_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 8);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate2("", ctx_r0.label, "", ctx_r0.required ? "*" : "", "");
  }
}
function LinqFileUploadComponent_span_14_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 9);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.error);
  }
}
function LinqFormlyFileUploadComponent_div_2_For_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4)(1, "span", 5);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "button", 6);
    i0.ɵɵlistener("click", function LinqFormlyFileUploadComponent_div_2_For_2_Template_button_click_3_listener() {
      const ɵ$index_9_r2 = i0.ɵɵrestoreView(_r1).$index;
      const ctx_r2 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r2.removeFile(ɵ$index_9_r2));
    });
    i0.ɵɵelementStart(4, "mat-icon");
    i0.ɵɵtext(5, "close");
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const file_r4 = ctx.$implicit;
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(file_r4.name);
  }
}
function LinqFormlyFileUploadComponent_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵrepeaterCreate(1, LinqFormlyFileUploadComponent_div_2_For_2_Template, 6, 1, "div", 4, i0.ɵɵrepeaterTrackByIdentity);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵrepeater(ctx_r2.files);
  }
}
const _c4 = (a0, a1) => ({
  width: a0,
  height: a1
});
function LinqFormlyRepeatComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "legend");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.props.label);
  }
}
function LinqFormlyRepeatComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.props.description);
  }
}
function LinqFormlyRepeatComponent_For_4_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "hr", 5);
  }
}
function LinqFormlyRepeatComponent_For_4_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 7)(1, "button", 8);
    i0.ɵɵlistener("click", function LinqFormlyRepeatComponent_For_4_Conditional_4_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r2);
      const $index_r3 = i0.ɵɵnextContext().$index;
      const ctx_r0 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r0.remove($index_r3));
    });
    i0.ɵɵtext(2);
    i0.ɵɵelementStart(3, "mat-icon");
    i0.ɵɵtext(4, "delete");
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.props["removeText"], " ");
  }
}
function LinqFormlyRepeatComponent_For_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵtemplate(1, LinqFormlyRepeatComponent_For_4_Conditional_1_Template, 1, 0, "hr", 5);
    i0.ɵɵelementStart(2, "div", 6);
    i0.ɵɵelement(3, "formly-field", 2);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(4, LinqFormlyRepeatComponent_For_4_Conditional_4_Template, 5, 1, "div", 7);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const _field_r4 = ctx.$implicit;
    const $index_r3 = ctx.$index;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵconditional($index_r3 > 0 ? 1 : -1);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("field", _field_r4);
    i0.ɵɵadvance();
    i0.ɵɵconditional($index_r3 > 0 || (ctx_r0.field.fieldGroup == null ? null : ctx_r0.field.fieldGroup.length) > 1 ? 4 : -1);
  }
}
const _c5 = (a0, a1) => ({
  "justify-content-end": a0,
  "justify-content-between": a1
});
function LinqFormlyStepperComponent_For_2_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const step_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵtextInterpolate(step_r1.props == null ? null : step_r1.props.label);
  }
}
function LinqFormlyStepperComponent_For_2_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "button", 5);
    i0.ɵɵtext(1, "Back");
    i0.ɵɵelementEnd();
  }
}
function LinqFormlyStepperComponent_For_2_Conditional_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "button", 6);
    i0.ɵɵtext(1, "Next");
    i0.ɵɵelementEnd();
  }
}
function LinqFormlyStepperComponent_For_2_Conditional_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "button", 7);
    i0.ɵɵtext(1, "Submit");
    i0.ɵɵelementEnd();
  }
}
function LinqFormlyStepperComponent_For_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-step", 1);
    i0.ɵɵtemplate(1, LinqFormlyStepperComponent_For_2_ng_template_1_Template, 1, 1, "ng-template", 2);
    i0.ɵɵelement(2, "formly-field", 3);
    i0.ɵɵelementStart(3, "div", 4);
    i0.ɵɵtemplate(4, LinqFormlyStepperComponent_For_2_Conditional_4_Template, 2, 0, "button", 5)(5, LinqFormlyStepperComponent_For_2_Conditional_5_Template, 2, 0, "button", 6)(6, LinqFormlyStepperComponent_For_2_Conditional_6_Template, 2, 0, "button", 7);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const step_r1 = ctx.$implicit;
    const $index_r2 = ctx.$index;
    const ɵ$index_3_r3 = ctx.$index;
    const ɵ$count_3_r4 = ctx.$count;
    i0.ɵɵproperty("stepControl", step_r1 == null ? null : step_r1.formControl);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("field", step_r1);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(6, _c5, $index_r2 === 0, $index_r2 > 0));
    i0.ɵɵadvance();
    i0.ɵɵconditional($index_r2 !== 0 ? 4 : -1);
    i0.ɵɵadvance();
    i0.ɵɵconditional(!(ɵ$index_3_r3 === ɵ$count_3_r4 - 1) ? 5 : -1);
    i0.ɵɵadvance();
    i0.ɵɵconditional(ɵ$index_3_r3 === ɵ$count_3_r4 - 1 ? 6 : -1);
  }
}
function LinqFormlyAccordionWrapperComponent_For_2_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-panel-description");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const field_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", field_r1.props == null ? null : field_r1.props["description"], " ");
  }
}
function LinqFormlyAccordionWrapperComponent_For_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-expansion-panel", 1)(1, "mat-expansion-panel-header")(2, "mat-panel-title");
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(4, LinqFormlyAccordionWrapperComponent_For_2_Conditional_4_Template, 2, 1, "mat-panel-description");
    i0.ɵɵelementEnd();
    i0.ɵɵelement(5, "formly-field", 2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const field_r1 = ctx.$implicit;
    i0.ɵɵproperty("disabled", field_r1.props == null ? null : field_r1.props["disabled"])("expanded", field_r1.props == null ? null : field_r1.props["expanded"]);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate1(" ", field_r1.props == null ? null : field_r1.props["label"], " ");
    i0.ɵɵadvance();
    i0.ɵɵconditional((field_r1.props == null ? null : field_r1.props["description"]) ? 4 : -1);
    i0.ɵɵadvance();
    i0.ɵɵproperty("field", field_r1);
  }
}
const _c6 = ["matPrefix"];
const _c7 = ["matSuffix"];
function LinqFormlyWrapperAddonsComponent_Conditional_0_ng_template_0_Conditional_0_Conditional_0_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "mat-icon", 5);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(5);
    i0.ɵɵproperty("svgIcon", ctx_r1.props["addonLeft"].svgIcon);
  }
}
function LinqFormlyWrapperAddonsComponent_Conditional_0_ng_template_0_Conditional_0_Conditional_0_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-icon");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(5);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r1.props["addonLeft"].tooltip && !ctx_r1.props["addonLeft"].icon ? "info" : ctx_r1.props["addonLeft"].icon, " ");
  }
}
function LinqFormlyWrapperAddonsComponent_Conditional_0_ng_template_0_Conditional_0_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 4);
    i0.ɵɵlistener("click", function LinqFormlyWrapperAddonsComponent_Conditional_0_ng_template_0_Conditional_0_Conditional_0_Template_button_click_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r1.addonLeftClick($event));
    });
    i0.ɵɵtemplate(1, LinqFormlyWrapperAddonsComponent_Conditional_0_ng_template_0_Conditional_0_Conditional_0_Conditional_1_Template, 1, 1, "mat-icon", 5)(2, LinqFormlyWrapperAddonsComponent_Conditional_0_ng_template_0_Conditional_0_Conditional_0_Conditional_2_Template, 2, 1, "mat-icon");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵproperty("matTooltip", ctx_r1.props["addonLeft"].tooltip);
    i0.ɵɵadvance();
    i0.ɵɵconditional(ctx_r1.props["addonLeft"].svgIcon ? 1 : 2);
  }
}
function LinqFormlyWrapperAddonsComponent_Conditional_0_ng_template_0_Conditional_0_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.props["addonLeft"].text);
  }
}
function LinqFormlyWrapperAddonsComponent_Conditional_0_ng_template_0_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, LinqFormlyWrapperAddonsComponent_Conditional_0_ng_template_0_Conditional_0_Conditional_0_Template, 3, 2, "button", 3)(1, LinqFormlyWrapperAddonsComponent_Conditional_0_ng_template_0_Conditional_0_Conditional_1_Template, 2, 1, "span");
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵconditional(ctx_r1.props["addonLeft"].icon || ctx_r1.props["addonLeft"].svgIcon || ctx_r1.props["addonLeft"].tooltip ? 0 : -1);
    i0.ɵɵadvance();
    i0.ɵɵconditional(ctx_r1.props["addonLeft"].text ? 1 : -1);
  }
}
function LinqFormlyWrapperAddonsComponent_Conditional_0_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, LinqFormlyWrapperAddonsComponent_Conditional_0_ng_template_0_Conditional_0_Template, 2, 2);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵconditional(ctx_r1.hasPrefix ? 0 : -1);
  }
}
function LinqFormlyWrapperAddonsComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, LinqFormlyWrapperAddonsComponent_Conditional_0_ng_template_0_Template, 1, 1, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
  }
}
function LinqFormlyWrapperAddonsComponent_ng_template_3_Conditional_0_Conditional_0_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "mat-icon", 5);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵproperty("svgIcon", ctx_r1.props["addonRight"].svgIcon);
  }
}
function LinqFormlyWrapperAddonsComponent_ng_template_3_Conditional_0_Conditional_0_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-icon");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.props["addonRight"].tooltip && !ctx_r1.props["addonRight"].icon ? "info" : ctx_r1.props["addonRight"].icon);
  }
}
function LinqFormlyWrapperAddonsComponent_ng_template_3_Conditional_0_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 4);
    i0.ɵɵlistener("click", function LinqFormlyWrapperAddonsComponent_ng_template_3_Conditional_0_Conditional_0_Template_button_click_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r1.addonRightClick($event));
    });
    i0.ɵɵtemplate(1, LinqFormlyWrapperAddonsComponent_ng_template_3_Conditional_0_Conditional_0_Conditional_1_Template, 1, 1, "mat-icon", 5)(2, LinqFormlyWrapperAddonsComponent_ng_template_3_Conditional_0_Conditional_0_Conditional_2_Template, 2, 1, "mat-icon");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("matTooltip", ctx_r1.props["addonRight"].tooltip);
    i0.ɵɵadvance();
    i0.ɵɵconditional(ctx_r1.props["addonRight"].svgIcon ? 1 : 2);
  }
}
function LinqFormlyWrapperAddonsComponent_ng_template_3_Conditional_0_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.props["addonRight"].text);
  }
}
function LinqFormlyWrapperAddonsComponent_ng_template_3_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, LinqFormlyWrapperAddonsComponent_ng_template_3_Conditional_0_Conditional_0_Template, 3, 2, "button", 3)(1, LinqFormlyWrapperAddonsComponent_ng_template_3_Conditional_0_Conditional_1_Template, 2, 1, "span");
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵconditional(ctx_r1.props["addonRight"].icon || ctx_r1.props["addonRight"].svgIcon || ctx_r1.props["addonRight"].tooltip ? 0 : -1);
    i0.ɵɵadvance();
    i0.ɵɵconditional(ctx_r1.props["addonRight"].text ? 1 : -1);
  }
}
function LinqFormlyWrapperAddonsComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, LinqFormlyWrapperAddonsComponent_ng_template_3_Conditional_0_Template, 2, 2);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵconditional(ctx_r1.props["addonRight"] ? 0 : -1);
  }
}
export { default as CreditCardValidator } from 'card-validator';
import * as i2$2 from 'ngx-mask';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { Subject, distinctUntilChanged, Observable, BehaviorSubject } from 'rxjs';
import { isEqual, debounce } from 'lodash';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { distinctUntilChanged as distinctUntilChanged$1 } from 'rxjs/operators';
class LinqFormlyValidationMessagesComponent {
  fields = [];
  debouceTimeMs = 250;
  errorMessages = new EventEmitter();
  touchedErrorMessages = new EventEmitter();
  #errorMessages = new Map();
  #$errorMessages = new Subject();
  constructor() {
    this.#$errorMessages.pipe(distinctUntilChanged((prev, curr) => isEqual(prev, curr))).subscribe(errorMessages => {
      this.errorMessages.emit(errorMessages);
      const touchedErrorMessages = new Map();
      errorMessages.forEach((errorMessage, key) => {
        if (errorMessage.touched) {
          touchedErrorMessages.set(key, errorMessage);
        }
      });
      this.touchedErrorMessages.emit(touchedErrorMessages);
    });
  }
  isTouchedError(field) {
    return !!(field?.formControl?.invalid && field?.formControl?.touched);
  }
  reset() {
    this.#errorMessages = new Map();
  }
  onValidationMessage(validationMessage) {
    const key = validationMessage.field?.id.toString();
    const errorMessage = validationMessage.errorMessage.toString();
    this.#errorMessages.set(key, {
      field: validationMessage.field,
      errorMessage,
      touched: this.isTouchedError(validationMessage.field)
    });
  }
  onUpdate = debounce(() => {
    this.#$errorMessages.next(this.#errorMessages);
  }, this.debouceTimeMs, {
    maxWait: this.debouceTimeMs
  });
  static ɵfac = function LinqFormlyValidationMessagesComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || LinqFormlyValidationMessagesComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: LinqFormlyValidationMessagesComponent,
    selectors: [["linq-formly-validation-messages"]],
    inputs: {
      fields: "fields",
      debouceTimeMs: "debouceTimeMs"
    },
    outputs: {
      errorMessages: "errorMessages",
      touchedErrorMessages: "touchedErrorMessages"
    },
    decls: 3,
    vars: 4,
    consts: [["errorList", ""], ["validationMessage", ""], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], [3, "field"]],
    template: function LinqFormlyValidationMessagesComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵtemplate(0, LinqFormlyValidationMessagesComponent_ng_template_0_Template, 2, 0, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor)(2, LinqFormlyValidationMessagesComponent_ng_container_2_Template, 1, 0, "ng-container", 2);
      }
      if (rf & 2) {
        const errorList_r7 = i0.ɵɵreference(1);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngTemplateOutlet", errorList_r7)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c0, ctx.fields));
      }
    },
    dependencies: [i1.NgTemplateOutlet, i1$1.ɵFormlyValidationMessage],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinqFormlyValidationMessagesComponent, [{
    type: Component,
    args: [{
      selector: 'linq-formly-validation-messages',
      template: `
    <ng-template #errorList let-fields="fields" let-level="level">
      @for (field of fields; track field) {
      <!-- Reset error messages on first iteration -->
      @if ($first && level === 1) {
      {{ reset() }}
      }

      <!-- Use formly's validation message to retrieve error message from a field -->
      <!-- https://github.com/ngx-formly/ngx-formly/issues/1297#issuecomment-444172637 -->
      <formly-validation-message #validationMessage [field]="field" [style.display]="'none'">
      </formly-validation-message>

      <!-- Store error message -->
      @if (validationMessage.errorMessage) {
      {{ onValidationMessage(validationMessage) }}
      }

      <!-- Recursive check for nested form fields -->
      <ng-container
        *ngTemplateOutlet="errorList; context: { fields: field.fieldGroup, level: level + 1 }"
      ></ng-container>

      <!-- Trigger update after grabbing all error messages -->
      @if ($last && level === 1) {
      {{ onUpdate() }}
      } }
    </ng-template>
    <ng-container *ngTemplateOutlet="errorList; context: { fields: fields, level: 1 }"></ng-container>
  `
    }]
  }], () => [], {
    fields: [{
      type: Input
    }],
    debouceTimeMs: [{
      type: Input
    }],
    errorMessages: [{
      type: Output
    }],
    touchedErrorMessages: [{
      type: Output
    }]
  });
})();
class LinqFormlyComponent {
  id = undefined;
  form = new FormGroup({});
  model = {};
  fields = [];
  options = {};
  submitted = new EventEmitter();
  errorMessages = new EventEmitter();
  touchedErrorMessages = new EventEmitter();
  submit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.submitted.emit(this.model);
    }
  }
  onErrorMessages(errorMessages) {
    this.errorMessages.emit(errorMessages);
  }
  onTouchedErrorMessages(touchedErrorMessages) {
    this.touchedErrorMessages.emit(touchedErrorMessages);
  }
  static ɵfac = function LinqFormlyComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || LinqFormlyComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: LinqFormlyComponent,
    selectors: [["linq-formly"]],
    inputs: {
      id: "id",
      form: "form",
      model: "model",
      fields: "fields",
      options: "options"
    },
    outputs: {
      submitted: "submitted",
      errorMessages: "errorMessages",
      touchedErrorMessages: "touchedErrorMessages"
    },
    decls: 3,
    vars: 7,
    consts: [[3, "ngSubmit", "id", "formGroup"], [3, "model", "fields", "options", "form"], [3, "touchedErrorMessages", "errorMessages", "fields"]],
    template: function LinqFormlyComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "form", 0);
        i0.ɵɵlistener("ngSubmit", function LinqFormlyComponent_Template_form_ngSubmit_0_listener() {
          return ctx.submit();
        });
        i0.ɵɵelement(1, "formly-form", 1);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(2, "linq-formly-validation-messages", 2);
        i0.ɵɵlistener("touchedErrorMessages", function LinqFormlyComponent_Template_linq_formly_validation_messages_touchedErrorMessages_2_listener($event) {
          return ctx.onTouchedErrorMessages($event);
        })("errorMessages", function LinqFormlyComponent_Template_linq_formly_validation_messages_errorMessages_2_listener($event) {
          return ctx.onErrorMessages($event);
        });
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵproperty("id", ctx.id)("formGroup", ctx.form);
        i0.ɵɵadvance();
        i0.ɵɵproperty("model", ctx.model)("fields", ctx.fields)("options", ctx.options)("form", ctx.form);
        i0.ɵɵadvance();
        i0.ɵɵproperty("fields", ctx.fields);
      }
    },
    dependencies: [i1$2.ɵNgNoValidate, i1$2.NgControlStatusGroup, i1$2.FormGroupDirective, i1$1.FormlyForm, LinqFormlyValidationMessagesComponent],
    styles: [".mat-mdc-form-field-subscript-dynamic-size .mat-mdc-form-field-hint-wrapper{margin-bottom:22px}\n"],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinqFormlyComponent, [{
    type: Component,
    args: [{
      selector: 'linq-formly',
      encapsulation: ViewEncapsulation.None,
      template: `
    <form [id]="id" [formGroup]="form" (ngSubmit)="submit()">
      <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
    </form>
    <linq-formly-validation-messages
      (touchedErrorMessages)="onTouchedErrorMessages($event)"
      (errorMessages)="onErrorMessages($event)"
      [fields]="fields"
    ></linq-formly-validation-messages>
  `,
      styles: [".mat-mdc-form-field-subscript-dynamic-size .mat-mdc-form-field-hint-wrapper{margin-bottom:22px}\n"]
    }]
  }], null, {
    id: [{
      type: Input
    }],
    form: [{
      type: Input
    }],
    model: [{
      type: Input
    }],
    fields: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    submitted: [{
      type: Output
    }],
    errorMessages: [{
      type: Output
    }],
    touchedErrorMessages: [{
      type: Output
    }]
  });
})();
const Masks = {
  Zip5: '00000',
  Zip9: '00000-0000',
  Zip: '00000||00000-0000',
  Phone: '000-000-0000',
  Decimal2: 'separator.2',
  // 1,111.11
  SSN: 'XXX-XX-XXXX',
  EIN: 'XX-XXXXXXX',
  Email: 'A*@A*.SSS',
  Password: 'X'.repeat(100),
  CreditCard: {
    visa: '0000 0000 0000 0000',
    mastercard: '0000 0000 0000 0000',
    'american-express': '0000 000000 00000',
    discover: '0000 0000 0000 0000',
    expiration: '00/00',
    cvc: '000||0000'
  }
};
const filterByAllowedExtensions = (files, component) => {
  const approved = [];
  const denied = [];
  files.forEach(file => {
    if (file && component.allowedExtensions.indexOf(`.${file.name.split('.').pop()}`) > -1) {
      approved.push(file);
    } else {
      denied.push(file);
    }
  });
  return {
    label: 'Allowed Extensions',
    approved,
    denied
  };
};
const filterByMaxFiles = (files, component) => {
  const approved = [...files];
  let denied = [];
  if (component.maxFiles && approved.length > component.maxFiles) {
    denied = approved.splice(component.maxFiles - approved.length);
  }
  return {
    label: 'Max Files',
    approved,
    denied
  };
};
const filterByMaxSize = (files, component) => {
  const approved = [];
  const denied = [];
  files.forEach(file => {
    if (file && file.size <= component.maxSize) {
      approved.push(file);
    } else {
      denied.push(file);
    }
  });
  return {
    label: 'Max Size',
    approved,
    denied
  };
};

/* eslint-disable */
const MB = 1024 * 1024;
const MAX_FILE_SIZE = 8 * MB;
const MAX_FILES = 6;
const defaultValidators = [filterByAllowedExtensions, filterByMaxFiles, filterByMaxSize];
class LinqFileUploadComponent {
  onFilesSelected = new EventEmitter();
  onFilesError = new EventEmitter();
  allowedExtensions = [];
  maxSize = MAX_FILE_SIZE;
  defaultValidators = true;
  disabled = false;
  error;
  label = 'File Upload';
  required = true;
  maxFiles;
  validators = [];
  preventDropOnBody = true;
  id;
  trackingId;
  className = true;
  active = false;
  multiple = false;
  uniqueId;
  constructor() {
    this.uniqueId = `fileUpload${(Math.random() * 100).toFixed()}`;
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#tojson_behavior
    // default behavior for File is to return an empty object
    File.prototype.toJSON = function () {
      return {
        name: this.name,
        size: this.size,
        type: this.type,
        lastModified: this.lastModified,
        webkitRelativePath: this.webkitRelativePath
      };
    };
  }
  ngOnInit() {
    this.mergeValidators([]);
  }
  ngOnChanges(simpleChanges) {
    if (simpleChanges['maxFiles']) {
      this.multiple = simpleChanges['maxFiles'].currentValue > 1;
    }
    if (simpleChanges['validators'] || simpleChanges['defaultValidators']) {
      this.mergeValidators(simpleChanges['validators'].currentValue);
    }
  }
  mergeValidators(validators) {
    this.validators = [...(this.defaultValidators ? defaultValidators : []), ...validators].filter(fn => fn);
  }
  fileBrowseHandler(event) {
    const fileList = event.target['files'];
    this.onFilesSelected.emit(this.validate(Array.from(fileList)));
  }
  validate(files) {
    const deniedFiles = {};
    const validatedFiles = this.validators.reduce((acc, validator) => {
      const {
        approved,
        denied,
        label
      } = validator(acc, this);
      if (denied) {
        deniedFiles[label] = denied;
      }
      return approved;
    }, files);
    if (Object.keys(deniedFiles).length) {
      this.onFilesError.emit(deniedFiles);
    }
    return validatedFiles;
  }
  onDropEvent(event) {
    event.preventDefault();
    if (this.disabled) {
      return;
    }
    const {
      dataTransfer
    } = event;
    if (dataTransfer.items) {
      const fileList = [];
      Array.from(dataTransfer.items).forEach(item => {
        if (item.kind === 'file') {
          fileList.push(item.getAsFile());
        }
      });
      dataTransfer.items.clear();
      this.onFilesSelected.emit(this.validate(fileList));
    } else {
      const fileList = dataTransfer.files;
      dataTransfer.clearData();
      this.onFilesSelected.emit(this.validate(Array.from(fileList)));
    }
    this.active = false;
  }
  onDragOverEvent(event) {
    event.stopPropagation();
    event.preventDefault();
    this.active = !this.disabled;
  }
  onDragLeaveEvent(event) {
    this.active = false;
  }
  onBodyDragOverEvent(event) {
    if (this.preventDropOnBody) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
  onBodyDropEvent(event) {
    if (this.preventDropOnBody) {
      event.preventDefault();
    }
  }
  static ɵfac = function LinqFileUploadComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || LinqFileUploadComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: LinqFileUploadComponent,
    selectors: [["linq-file-upload"]],
    hostVars: 2,
    hostBindings: function LinqFileUploadComponent_HostBindings(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵlistener("drop", function LinqFileUploadComponent_drop_HostBindingHandler($event) {
          return ctx.onDropEvent($event);
        })("dragover", function LinqFileUploadComponent_dragover_HostBindingHandler($event) {
          return ctx.onDragOverEvent($event);
        })("dragleave", function LinqFileUploadComponent_dragleave_HostBindingHandler($event) {
          return ctx.onDragLeaveEvent($event);
        })("dragover", function LinqFileUploadComponent_dragover_HostBindingHandler($event) {
          return ctx.onBodyDragOverEvent($event);
        }, false, i0.ɵɵresolveBody)("drop", function LinqFileUploadComponent_drop_HostBindingHandler($event) {
          return ctx.onBodyDropEvent($event);
        }, false, i0.ɵɵresolveBody);
      }
      if (rf & 2) {
        i0.ɵɵclassProp("linq-file-upload", ctx.className);
      }
    },
    inputs: {
      allowedExtensions: "allowedExtensions",
      maxSize: "maxSize",
      defaultValidators: "defaultValidators",
      disabled: "disabled",
      error: "error",
      label: "label",
      required: "required",
      maxFiles: "maxFiles",
      validators: "validators",
      preventDropOnBody: "preventDropOnBody",
      id: "id",
      trackingId: "trackingId"
    },
    outputs: {
      onFilesSelected: "onFilesSelected",
      onFilesError: "onFilesError"
    },
    features: [i0.ɵɵNgOnChangesFeature],
    decls: 15,
    vars: 17,
    consts: [[1, "linq-file-upload-target-wrapper", 3, "id", "ngClass"], ["class", "linq-file-upload-label", 4, "ngIf"], ["dragAndDrop", "", "tabindex", "0", 1, "linq-file-upload-target", 3, "ngClass"], [1, "linq-file-upload-target__content"], [1, "linq-file-upload-target__icon"], [3, "for"], ["type", "file", 3, "change", "disabled", "id", "multiple", "accept"], ["class", "linq-file-upload-file-error", 4, "ngIf"], [1, "linq-file-upload-label"], [1, "linq-file-upload-file-error"]],
    template: function LinqFileUploadComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, LinqFileUploadComponent_span_1_Template, 2, 2, "span", 1);
        i0.ɵɵelementStart(2, "div", 2)(3, "div", 3)(4, "mat-icon", 4);
        i0.ɵɵtext(5, "cloud_upload");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(6, "span");
        i0.ɵɵtext(7, " Drag and Drop here");
        i0.ɵɵelement(8, "br");
        i0.ɵɵtext(9, " or");
        i0.ɵɵelement(10, "br");
        i0.ɵɵelementStart(11, "label", 5);
        i0.ɵɵtext(12, "Browse");
        i0.ɵɵelementEnd()();
        i0.ɵɵelementStart(13, "input", 6);
        i0.ɵɵlistener("change", function LinqFileUploadComponent_Template_input_change_13_listener($event) {
          return ctx.fileBrowseHandler($event);
        });
        i0.ɵɵelementEnd()()();
        i0.ɵɵtemplate(14, LinqFileUploadComponent_span_14_Template, 2, 1, "span", 7);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵproperty("id", ctx.id || null)("ngClass", i0.ɵɵpureFunction3(11, _c2, ctx.error, ctx.active, ctx.disabled));
        i0.ɵɵattribute("data-id", ctx.trackingId || null);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.label);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(15, _c3, ctx.error));
        i0.ɵɵadvance(9);
        i0.ɵɵproperty("for", ctx.uniqueId);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("disabled", ctx.disabled)("id", ctx.uniqueId)("multiple", ctx.multiple)("accept", ctx.allowedExtensions.join(","));
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.error);
      }
    },
    dependencies: [i1.NgClass, i1.NgIf, i2.MatIcon],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinqFileUploadComponent, [{
    type: Component,
    args: [{
      selector: 'linq-file-upload',
      template: `
    <div
      class="linq-file-upload-target-wrapper"
      [id]="id || null"
      [attr.data-id]="trackingId || null"
      [ngClass]="{
        'linq-file-upload-target-wrapper--error': error,
        'linq-file-upload-target-wrapper--active': active,
        'linq-file-upload-target-wrapper--disabled': disabled,
      }"
    >
      <span *ngIf="label" class="linq-file-upload-label">{{ label }}{{ required ? '*' : '' }}</span>
      <div
        class="linq-file-upload-target"
        [ngClass]="{ 'linq-file-upload-target--error': error }"
        dragAndDrop
        tabindex="0"
      >
        <div class="linq-file-upload-target__content">
          <mat-icon class="linq-file-upload-target__icon">cloud_upload</mat-icon>
          <span>
            Drag and Drop here<br />
            or<br />
            <label [for]="uniqueId">Browse</label>
          </span>
          <input
            [disabled]="disabled"
            [id]="uniqueId"
            type="file"
            [multiple]="multiple"
            [accept]="allowedExtensions.join(',')"
            (change)="fileBrowseHandler($event)"
          />
        </div>
      </div>
      <span *ngIf="error" class="linq-file-upload-file-error">{{ error }}</span>
    </div>
  `
    }]
  }], () => [], {
    onFilesSelected: [{
      type: Output
    }],
    onFilesError: [{
      type: Output
    }],
    allowedExtensions: [{
      type: Input
    }],
    maxSize: [{
      type: Input
    }],
    defaultValidators: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    error: [{
      type: Input
    }, {
      type: Optional
    }],
    label: [{
      type: Input
    }],
    required: [{
      type: Input
    }],
    maxFiles: [{
      type: Input
    }, {
      type: Optional
    }],
    validators: [{
      type: Input
    }],
    preventDropOnBody: [{
      type: Input
    }],
    id: [{
      type: Input
    }, {
      type: Optional
    }],
    trackingId: [{
      type: Input
    }, {
      type: Optional
    }],
    className: [{
      type: HostBinding,
      args: ['class.linq-file-upload']
    }],
    onDropEvent: [{
      type: HostListener,
      args: ['drop', ['$event']]
    }],
    onDragOverEvent: [{
      type: HostListener,
      args: ['dragover', ['$event']]
    }],
    onDragLeaveEvent: [{
      type: HostListener,
      args: ['dragleave', ['$event']]
    }],
    onBodyDragOverEvent: [{
      type: HostListener,
      args: ['body:dragover', ['$event']]
    }],
    onBodyDropEvent: [{
      type: HostListener,
      args: ['body:drop', ['$event']]
    }]
  });
})();
class LinqFileUploadModule {
  static ɵfac = function LinqFileUploadModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || LinqFileUploadModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: LinqFileUploadModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, MatIconModule, MatProgressBarModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinqFileUploadModule, [{
    type: NgModule,
    args: [{
      declarations: [LinqFileUploadComponent],
      imports: [CommonModule, MatIconModule, MatProgressBarModule],
      exports: [LinqFileUploadComponent]
    }]
  }], null, null);
})();
function addonsExtension(field) {
  if (!field.props || field.wrappers && field.wrappers.indexOf('addons') !== -1) {
    return;
  }
  if (field.props['addonLeft'] || field.props['addonRight']) {
    field.wrappers = [...(field.wrappers || []), 'addons'];
  }
}
class LinqFormlyButtonComponent extends FieldType {
  onClick($event) {
    if (this.props.onClick) {
      this.props.onClick($event);
    }
  }
  static ɵfac = /* @__PURE__ */(() => {
    let ɵLinqFormlyButtonComponent_BaseFactory;
    return function LinqFormlyButtonComponent_Factory(__ngFactoryType__) {
      return (ɵLinqFormlyButtonComponent_BaseFactory || (ɵLinqFormlyButtonComponent_BaseFactory = i0.ɵɵgetInheritedFactory(LinqFormlyButtonComponent)))(__ngFactoryType__ || LinqFormlyButtonComponent);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: LinqFormlyButtonComponent,
    selectors: [["linq-formly-mat-button"]],
    features: [i0.ɵɵInheritDefinitionFeature],
    decls: 2,
    vars: 4,
    consts: [["mat-flat-button", "", 3, "click", "ngClass", "disabled", "type"]],
    template: function LinqFormlyButtonComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "button", 0);
        i0.ɵɵlistener("click", function LinqFormlyButtonComponent_Template_button_click_0_listener($event) {
          return ctx.onClick($event);
        });
        i0.ɵɵtext(1);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngClass", ctx.props.className)("disabled", ctx.props.disabled || false)("type", ctx.props.type || "button");
        i0.ɵɵadvance();
        i0.ɵɵtextInterpolate1(" ", ctx.props.label, " ");
      }
    },
    dependencies: [i1.NgClass, i2$1.MatButton],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinqFormlyButtonComponent, [{
    type: Component,
    args: [{
      selector: 'linq-formly-mat-button',
      template: `
    <button
      [ngClass]="props.className"
      mat-flat-button
      [disabled]="props.disabled || false"
      (click)="onClick($event)"
      [type]="props.type || 'button'"
    >
      {{ props.label }}
    </button>
  `
    }]
  }], null, null);
})();
class LinqFormlyFileUploadComponent extends FieldType {
  error = undefined;
  defaultAllowedExtensions = ['.pdf', '.jpg', '.jpeg', '.png'];
  allowedExtensions = [];
  ngOnInit() {
    const allowedExtensions = [...this.props.allowedExtensions].filter(Boolean);
    if (allowedExtensions.length === 0) {
      allowedExtensions.push(...this.defaultAllowedExtensions);
    }
    this.allowedExtensions = allowedExtensions;
  }
  files = [];
  handleSelectedFiles(files) {
    this.files = files;
    this.formControl.setValue(files);
  }
  handleFileError(error) {
    console.log('Error:', error);
  }
  removeFile(index) {
    this.files.splice(index, 1);
  }
  static ɵfac = /* @__PURE__ */(() => {
    let ɵLinqFormlyFileUploadComponent_BaseFactory;
    return function LinqFormlyFileUploadComponent_Factory(__ngFactoryType__) {
      return (ɵLinqFormlyFileUploadComponent_BaseFactory || (ɵLinqFormlyFileUploadComponent_BaseFactory = i0.ɵɵgetInheritedFactory(LinqFormlyFileUploadComponent)))(__ngFactoryType__ || LinqFormlyFileUploadComponent);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: LinqFormlyFileUploadComponent,
    selectors: [["linq-formly-file-upload"]],
    features: [i0.ɵɵInheritDefinitionFeature],
    decls: 3,
    vars: 8,
    consts: [[1, "linq-file-upload"], [3, "onFilesSelected", "onFilesError", "label", "allowedExtensions", "error", "maxFiles", "maxSize", "disabled", "required"], ["class", "linq-file-upload-file-list", 4, "ngIf"], [1, "linq-file-upload-file-list"], [1, "linq-file-upload-file"], [1, "linq-file-upload-file__filename"], ["mat-icon-button", "", "aria-label", "Remove file", 1, "linq-file-upload-file__icon", 3, "click"]],
    template: function LinqFormlyFileUploadComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0)(1, "linq-file-upload", 1);
        i0.ɵɵlistener("onFilesSelected", function LinqFormlyFileUploadComponent_Template_linq_file_upload_onFilesSelected_1_listener($event) {
          return ctx.handleSelectedFiles($event);
        })("onFilesError", function LinqFormlyFileUploadComponent_Template_linq_file_upload_onFilesError_1_listener($event) {
          return ctx.handleFileError($event);
        });
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(2, LinqFormlyFileUploadComponent_div_2_Template, 3, 0, "div", 2);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        let tmp_3_0;
        let tmp_5_0;
        let tmp_6_0;
        i0.ɵɵadvance();
        i0.ɵɵproperty("label", ctx.props.label)("allowedExtensions", ctx.allowedExtensions)("error", ctx.error)("maxFiles", (tmp_3_0 = ctx.props.maxFiles) !== null && tmp_3_0 !== undefined ? tmp_3_0 : 10)("maxSize", ctx.props.maxSize)("disabled", (tmp_5_0 = ctx.props.disabled) !== null && tmp_5_0 !== undefined ? tmp_5_0 : false)("required", (tmp_6_0 = ctx.props.required) !== null && tmp_6_0 !== undefined ? tmp_6_0 : true);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.files);
      }
    },
    dependencies: [i1.NgIf, LinqFileUploadComponent, i2$1.MatIconButton, i2.MatIcon],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinqFormlyFileUploadComponent, [{
    type: Component,
    args: [{
      selector: 'linq-formly-file-upload',
      template: `
    <div class="linq-file-upload">
      <linq-file-upload
        [label]="props.label!"
        [allowedExtensions]="allowedExtensions"
        [error]="error"
        [maxFiles]="props.maxFiles ?? 10"
        [maxSize]="props.maxSize"
        (onFilesSelected)="handleSelectedFiles($event)"
        (onFilesError)="handleFileError($event)"
        [disabled]="props.disabled ?? false"
        [required]="props.required ?? true"
      ></linq-file-upload>
      <div *ngIf="files" class="linq-file-upload-file-list">
        @for (file of files; track file; let i = $index) {
        <div class="linq-file-upload-file">
          <span class="linq-file-upload-file__filename">{{ file.name }}</span>
          <button class="linq-file-upload-file__icon" mat-icon-button aria-label="Remove file" (click)="removeFile(i)">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        }
      </div>
    </div>
  `
    }]
  }], null, null);
})();
class LinqFormlyHrComponent extends FieldType {
  width = this.props.width ?? '100%';
  height = this.props.height ?? '1px';
  static ɵfac = /* @__PURE__ */(() => {
    let ɵLinqFormlyHrComponent_BaseFactory;
    return function LinqFormlyHrComponent_Factory(__ngFactoryType__) {
      return (ɵLinqFormlyHrComponent_BaseFactory || (ɵLinqFormlyHrComponent_BaseFactory = i0.ɵɵgetInheritedFactory(LinqFormlyHrComponent)))(__ngFactoryType__ || LinqFormlyHrComponent);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: LinqFormlyHrComponent,
    selectors: [["linq-formly-hr"]],
    features: [i0.ɵɵInheritDefinitionFeature],
    decls: 1,
    vars: 4,
    consts: [[1, "divider", 3, "ngStyle"]],
    template: function LinqFormlyHrComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelement(0, "hr", 0);
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction2(1, _c4, ctx.width, ctx.height));
      }
    },
    dependencies: [i1.NgStyle],
    styles: [".divider[_ngcontent-%COMP%]{border:0;border-top:1px solid #b5b5b5;margin-top:4px;margin-bottom:4px}"]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinqFormlyHrComponent, [{
    type: Component,
    args: [{
      selector: 'linq-formly-hr',
      template: `<hr [ngStyle]="{ width: this.width, height: this.height }" class="divider" />`,
      styles: [".divider{border:0;border-top:1px solid #b5b5b5;margin-top:4px;margin-bottom:4px}\n"]
    }]
  }], null, null);
})();
class LinqFormlyMaskedInputComponent extends FieldType$1 {
  destroyRef = inject(DestroyRef);
  #maskValue = '';
  get mask() {
    return this.#maskValue;
  }
  ngOnInit() {
    if (this.props.mask instanceof Observable) {
      if (this.props.mask.value) {
        this.#maskValue = this.props.mask.value;
      }
      this.props.mask.pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged$1()).subscribe(value => {
        this.#maskValue = value;
      });
    }
    if (typeof this.props.mask === 'string') {
      this.#maskValue = this.props.mask;
    }
  }
  static ɵfac = /* @__PURE__ */(() => {
    let ɵLinqFormlyMaskedInputComponent_BaseFactory;
    return function LinqFormlyMaskedInputComponent_Factory(__ngFactoryType__) {
      return (ɵLinqFormlyMaskedInputComponent_BaseFactory || (ɵLinqFormlyMaskedInputComponent_BaseFactory = i0.ɵɵgetInheritedFactory(LinqFormlyMaskedInputComponent)))(__ngFactoryType__ || LinqFormlyMaskedInputComponent);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: LinqFormlyMaskedInputComponent,
    selectors: [["linq-formly-masked-input"]],
    features: [i0.ɵɵInheritDefinitionFeature],
    decls: 1,
    vars: 19,
    consts: [["matInput", "", 3, "id", "name", "required", "readonly", "placeholder", "mask", "showMaskTyped", "patterns", "dropSpecialCharacters", "clearIfNotMatch", "errorStateMatcher", "hiddenInput", "allowNegativeNumbers", "formControl", "formlyAttributes", "thousandSeparator", "shownMaskExpression", "validation"]],
    template: function LinqFormlyMaskedInputComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelement(0, "input", 0);
      }
      if (rf & 2) {
        let tmp_8_0;
        let tmp_18_0;
        i0.ɵɵproperty("id", ctx.id)("name", ctx.field.name)("required", ctx.required)("readonly", ctx.props.readonly)("placeholder", ctx.props.placeholder)("mask", ctx.mask)("showMaskTyped", ctx.props.showMaskTyped)("patterns", ctx.props.patterns)("dropSpecialCharacters", (tmp_8_0 = ctx.props.dropSpecialCharacters) !== null && tmp_8_0 !== undefined ? tmp_8_0 : true)("clearIfNotMatch", ctx.props.clearIfNotMatch)("errorStateMatcher", ctx.errorStateMatcher)("hiddenInput", ctx.props.hiddenInput)("allowNegativeNumbers", ctx.props.allowNegativeNumbers || false)("formControl", ctx.formControl)("formlyAttributes", ctx.field)("allowNegativeNumbers", ctx.props.allowNegativeNumbers)("thousandSeparator", ctx.props.thousandSeparator || ",")("shownMaskExpression", ctx.props.shownMaskExpression)("validation", (tmp_18_0 = ctx.props.validation) !== null && tmp_18_0 !== undefined ? tmp_18_0 : true);
      }
    },
    dependencies: [i1$2.DefaultValueAccessor, i1$2.NgControlStatus, i1$2.RequiredValidator, i1$2.FormControlDirective, i2$2.NgxMaskDirective, i1$1.ɵFormlyAttributes, i4.MatInput],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinqFormlyMaskedInputComponent, [{
    type: Component,
    args: [{
      selector: 'linq-formly-masked-input',
      template: `
    <input
      [id]="id"
      matInput
      [name]="field.name"
      [required]="required"
      [readonly]="props.readonly"
      [placeholder]="props.placeholder"
      [mask]="mask"
      [showMaskTyped]="props.showMaskTyped"
      [patterns]="props.patterns"
      [dropSpecialCharacters]="props.dropSpecialCharacters ?? true"
      [clearIfNotMatch]="props.clearIfNotMatch"
      [errorStateMatcher]="errorStateMatcher"
      [hiddenInput]="props.hiddenInput"
      [allowNegativeNumbers]="props.allowNegativeNumbers || false"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [allowNegativeNumbers]="props.allowNegativeNumbers"
      [thousandSeparator]="props.thousandSeparator || ','"
      [shownMaskExpression]="props.shownMaskExpression"
      [validation]="props.validation ?? true"
    />
  `
    }]
  }], null, null);
})();
class LinqFormlyRepeatComponent extends FieldArrayType {
  static ɵfac = /* @__PURE__ */(() => {
    let ɵLinqFormlyRepeatComponent_BaseFactory;
    return function LinqFormlyRepeatComponent_Factory(__ngFactoryType__) {
      return (ɵLinqFormlyRepeatComponent_BaseFactory || (ɵLinqFormlyRepeatComponent_BaseFactory = i0.ɵɵgetInheritedFactory(LinqFormlyRepeatComponent)))(__ngFactoryType__ || LinqFormlyRepeatComponent);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: LinqFormlyRepeatComponent,
    selectors: [["linq-formly-repeat-section"]],
    features: [i0.ɵɵInheritDefinitionFeature],
    decls: 10,
    vars: 4,
    consts: [[1, "mb-3"], [1, "row"], [3, "field"], [1, "d-flex", "justify-content-end"], ["mat-flat-button", "", "type", "button", 3, "click"], [1, "linq-divider"], [1, "col-10"], [1, "col-2"], ["mat-button", "", "type", "button", "color", "warn", 3, "click"]],
    template: function LinqFormlyRepeatComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, LinqFormlyRepeatComponent_Conditional_1_Template, 2, 1, "legend")(2, LinqFormlyRepeatComponent_Conditional_2_Template, 2, 1, "p");
        i0.ɵɵrepeaterCreate(3, LinqFormlyRepeatComponent_For_4_Template, 5, 3, "div", 1, i0.ɵɵrepeaterTrackByIdentity);
        i0.ɵɵelementStart(5, "mat-error");
        i0.ɵɵelement(6, "formly-validation-message", 2);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(7, "div", 3)(8, "button", 4);
        i0.ɵɵlistener("click", function LinqFormlyRepeatComponent_Template_button_click_8_listener() {
          return ctx.add();
        });
        i0.ɵɵtext(9);
        i0.ɵɵelementEnd()()();
      }
      if (rf & 2) {
        i0.ɵɵadvance();
        i0.ɵɵconditional(ctx.props.label ? 1 : -1);
        i0.ɵɵadvance();
        i0.ɵɵconditional(ctx.props.description ? 2 : -1);
        i0.ɵɵadvance();
        i0.ɵɵrepeater(ctx.field.fieldGroup);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("field", ctx.field);
        i0.ɵɵadvance(3);
        i0.ɵɵtextInterpolate1(" ", ctx.props["addText"], " ");
      }
    },
    dependencies: [i1$1.FormlyField, i1$1.ɵFormlyValidationMessage, i2$1.MatButton, i2.MatIcon, i4$1.MatError],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinqFormlyRepeatComponent, [{
    type: Component,
    args: [{
      selector: 'linq-formly-repeat-section',
      template: `
    <div class="mb-3">
      @if (props.label) {
      <legend>{{ props.label }}</legend>
      } @if (props.description) {
      <p>{{ props.description }}</p>
      } @for (_field of field.fieldGroup; track _field) {
      <div class="row">
        @if ($index > 0) {
        <hr class="linq-divider" />
        }
        <div class="col-10">
          <formly-field [field]="_field"></formly-field>
        </div>
        @if ($index > 0 || field.fieldGroup?.length! > 1) {
        <div class="col-2">
          <button mat-button type="button" color="warn" (click)="remove($index)">
            {{ props['removeText'] }}
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        }
      </div>
      }

      <mat-error>
        <formly-validation-message [field]="field"></formly-validation-message>
      </mat-error>

      <div class="d-flex justify-content-end">
        <button mat-flat-button type="button" (click)="add()">
          {{ props['addText'] }}
        </button>
      </div>
    </div>
  `
    }]
  }], null, null);
})();
class LinqFormlyStepperComponent extends FieldType {
  stepper;
  ngAfterViewInit() {
    this.field.props = {
      ...this.field.props,
      ref: this.stepper
    };
  }
  isValid(field) {
    if (field.key) {
      return !!field?.formControl?.valid;
    }
    return field.fieldGroup ? field.fieldGroup.every(f => this.isValid(f)) : true;
  }
  static ɵfac = /* @__PURE__ */(() => {
    let ɵLinqFormlyStepperComponent_BaseFactory;
    return function LinqFormlyStepperComponent_Factory(__ngFactoryType__) {
      return (ɵLinqFormlyStepperComponent_BaseFactory || (ɵLinqFormlyStepperComponent_BaseFactory = i0.ɵɵgetInheritedFactory(LinqFormlyStepperComponent)))(__ngFactoryType__ || LinqFormlyStepperComponent);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: LinqFormlyStepperComponent,
    selectors: [["linq-formly-field-stepper"]],
    viewQuery: function LinqFormlyStepperComponent_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(MatStepper, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.stepper = _t.first);
      }
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    decls: 3,
    vars: 0,
    consts: [[1, "linq-stepper"], [3, "stepControl"], ["matStepLabel", ""], [3, "field"], [1, "d-flex", "flex-row", "linq-mt-5", 3, "ngClass"], ["mat-button", "", "mat-stroked-button", "", "matStepperPrevious", "", "type", "button"], ["mat-button", "", "mat-stroked-button", "", "matStepperNext", "", "type", "button", 1, "float-end"], ["mat-button", "", "mat-stroked-button", "", "type", "submit"]],
    template: function LinqFormlyStepperComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "mat-stepper", 0);
        i0.ɵɵrepeaterCreate(1, LinqFormlyStepperComponent_For_2_Template, 7, 9, "mat-step", 1, i0.ɵɵrepeaterTrackByIdentity);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵadvance();
        i0.ɵɵrepeater(ctx.field.fieldGroup);
      }
    },
    dependencies: [i1.NgClass, i1$1.FormlyField, i2$1.MatButton, i4$2.MatStep, i4$2.MatStepLabel, i4$2.MatStepper, i4$2.MatStepperNext, i4$2.MatStepperPrevious],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinqFormlyStepperComponent, [{
    type: Component,
    args: [{
      selector: 'linq-formly-field-stepper',
      template: `
    <mat-stepper class="linq-stepper">
      @for (step of field.fieldGroup; track step) {
      <mat-step [stepControl]="step?.formControl!">
        <ng-template matStepLabel>{{ step.props?.label }}</ng-template>
        <formly-field [field]="step"></formly-field>
        <div
          class="d-flex flex-row linq-mt-5"
          [ngClass]="{ 'justify-content-end': $index === 0, 'justify-content-between': $index > 0 }"
        >
          @if ($index !== 0) {
          <button mat-button mat-stroked-button matStepperPrevious type="button">Back</button>
          } @if (!$last) {
          <button mat-button mat-stroked-button matStepperNext type="button" class="float-end">Next</button>
          } @if ($last) {
          <button mat-button mat-stroked-button type="submit">Submit</button>
          }
        </div>
      </mat-step>
      }
    </mat-stepper>
  `
    }]
  }], null, {
    stepper: [{
      type: ViewChild,
      args: [MatStepper]
    }]
  });
})();
const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
/**
 * Finds the stepper that the field is in
 * @param field
 * @returns
 */
const findStepper = field => {
  const stepperField = findStepperField(field);
  return stepperField?.props?.['ref'];
};
/**
 * Finds the stepper field that the field is in
 * @param field
 * @returns
 */
const findStepperField = field => {
  if (field.type === LinqFormlyTypes.stepper) {
    return field;
  }
  if (field.parent) {
    return findStepperField(field.parent);
  }
  return undefined;
};
/**
 * Given a field, find the available steps
 * @param field
 * @returns
 */
const findStepperSteps = field => {
  const stepperField = findStepperField(field);
  const fieldGroup = stepperField?.fieldGroup || [];
  return fieldGroup.reduce((acc, current, index) => {
    if (current.key) {
      acc[current.key] = index;
    }
    return acc;
  }, {});
};
/**
 * Finds the step that the field is in
 * @param field
 */
const findStep = field => {
  const path = calculatePath(field, '');
  const steps = findStepperSteps(field);
  const paths = path.split('.');
  const fieldStep = Object.keys(steps).find(key => paths.includes(key));
  return steps[fieldStep];
};
/**
 * Sets the stepper to the step that the field is in
 * @param field
 */
const setStep = async field => {
  const step = findStep(field);
  const stepper = findStepper(field);
  if (stepper && !Number.isNaN(step)) {
    const currentIndex = stepper.selectedIndex;
    const nextIndex = step;
    stepper.selectedIndex = nextIndex;
    if (currentIndex !== nextIndex) {
      await delay(500); // 500ms delay (default stepper animation duration)
    }
  }
};
/**
 * Expands the panel that field is in
 * @param field
 */
const expandPanel = async field => {
  if (field?.parent?.props?.['ref']) {
    const ref = field.parent.props['ref'];
    const currentExpanded = ref.expanded;
    ref.expanded = true;
    if (!currentExpanded) {
      // wait if the panel was not already expanded
      await delay(225); // 225ms https://material.angular.io/components/expansion/api#EXPANSION_PANEL_ANIMATION_TIMING
    }
  }
};
/**
 * Focuses the html element of the field
 * @param field
 */
const focusField = async field => {
  // eslint-disable-next-line no-undef
  const nativeElement = document.getElementById(field.id);
  if (nativeElement) {
    nativeElement.scrollIntoView({
      behavior: 'smooth'
    });
    await delay(100);
    nativeElement.click();
  }
};
/**
 * Calculates the path of the field
 * @param field
 * @param path
 */
const calculatePath = (field, path = '') => {
  if (!field) {
    return path;
  }
  if (field.parent) {
    if (field.key && path) {
      path = `${field.key}.${path}`;
    } else if (field.key && !path) {
      path = `${field.key}`;
    }
    return calculatePath(field.parent, path);
  }
  return path;
};
class Form {
  static HorizontalSpacer(col) {
    let className = 'col';
    if (col) {
      className = `col-${col}`;
    }
    return {
      className,
      template: '<div></div>'
    };
  }
  static Br() {
    return this.HorizontalSpacer(12);
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const getFieldName = field => {
  if (!field) {
    return 'This field';
  }
  const fieldName = field.props?.['errorLabel'] || field.props?.label || field.key || 'This field';
  return fieldName;
};
const minDateValidationMessage = err => `This value should be more than ${err.min}`;
const maxDateValidationMessage = err => `This value should be less than ${err.max}`;
const minLengthValidationMessage = (err, field) => `Should have at least ${field.props?.minLength} characters`;
const maxLengthValidationMessage = (err, field) => `This value should be less than ${field.props.maxLength} characters`;
const minValidationMessage = (err, field) => `This value should be equal or more than ${field.props.min}`;
const maxValidationMessage = (err, field) => `This value should be equal or less than ${field.props.max}`;
const maxDecimalPlacesValidationMessage = (err, field) => `Should be max of ${field.templateOptions.maxDecimalPlaces} decimal places`;
const maskValidationMessage = (err, field) => {
  const fieldName = getFieldName(field);
  return `${fieldName} is invalid`;
};
const requiredValidationMessage = (err, field) => {
  const fieldName = getFieldName(field);
  return `${fieldName} is required`;
};
const hasBegEndWhitespaceMessage = (err, field) => {
  const fieldName = getFieldName(field);
  return `${fieldName} must not begin or end with blank space`;
};
const minTimeValidationMessage = (err, field) => {
  const fieldName = getFieldName(field);
  return `${fieldName} cannot be before start time`;
};
const maxTimeValidationMessage = (err, field) => {
  const fieldName = getFieldName(field);
  return `${fieldName} cannot be after end time`;
};
const matchMessage = fields => {
  const fieldNames = Object.values(fields).join(', ');
  return `${fieldNames} must match`;
};
const emailMessage = (err, field) => {
  const fieldName = getFieldName(field);
  return `${fieldName} has invalid email address`;
};
const urlMessage = (err, field) => {
  const fieldName = getFieldName(field);
  return `${fieldName} is invalid`;
};
const requiredTrueMessage = (err, field) => {
  const fieldName = getFieldName(field);
  return `${fieldName} is required`;
};
const urlValidator = control => {
  const value = control.value;
  const pattern = new RegExp('^(https?:\\/\\/)?' +
  // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
  // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' +
  // OR IP (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
  // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?' +
  // query string
  '(\\#[-a-z\\d_]*)?$',
  // fragment locator
  'i');
  const isValid = pattern.test(value);
  if (!isValid) {
    return {
      url: true
    };
  }
  return null;
};
const matchValidator = (control, _, options) => {
  const {
    fields,
    touch
  } = options;
  if (fields.length < 2) {
    return null;
  }
  const values = fields.map(field => control?.value?.[field]);
  const allMatching = values.every(value => value === values[0]);
  if (allMatching) {
    return null;
  }
  const controls = fields.map(field => control.get(field));
  if (touch) {
    controls.forEach(c => c?.markAsTouched?.());
  }
  const labels = controls.map(c => {
    const field = c?._fields?.[0];
    const fieldName = getFieldName(field);
    return fieldName;
  });
  return {
    match: Array.from(new Set(labels))
  };
};
const minAgeMessage = (err, field) => {
  return `Must be ${field.props.minAge} years of age or older`;
};
const minAgeValidator = (control, field, options) => {
  const minAge = field.props.minAge || options.minAge;
  const value = control.value;
  const date = new Date(value);
  const now = new Date();
  let age = now.getFullYear() - date.getFullYear();
  const month = now.getMonth() - date.getMonth();
  if (month < 0 || month === 0 && now.getDate() < date.getDate()) {
    age--;
  }
  if (age < minAge) {
    return {
      minAge: true
    };
  }
  return null;
};
const duplicateRecordMessage = (err, field) => {
  const fieldName = getFieldName(field);
  return `${fieldName} is not available`;
};
const apiErrorMessage = err => {
  return err.msg || err.message || JSON.stringify(err);
};
const cardExpirationValidator = (control, field, options) => {
  const value = control.value;
  const validation = CreditCardValidator.expirationDate(value);
  if (!validation.isValid) {
    return {
      cardExpiration: validation
    };
  }
  return null;
};
const cardCvcValidator = (control, field, options) => {
  const value = control.value;
  const validation = CreditCardValidator.cvv(value);
  if (!validation.isValid) {
    return {
      cardCvc: validation
    };
  }
  return null;
};
const cardNumberValidator = (control, field, options) => {
  const value = control.value;
  const supportedCards = field.props.supportedCards || [];
  const cardValidation = CreditCardValidator.number(value);
  cardValidation.supportedCards = supportedCards;
  const cardType = cardValidation.card?.type;
  cardValidation.isSupported = cardType ? supportedCards.includes(cardType) : true;
  if (!cardValidation.isValid || !cardValidation.isSupported) {
    return {
      cardNumber: cardValidation
    };
  }
  return null;
};
const cardNumberMessage = (err, field) => {
  const fieldName = getFieldName(field);
  if (!err.isSupported) {
    const niceType = err.card?.niceType || 'Card';
    return `${niceType} is not supported`;
  }
  return `${fieldName} is invalid`;
};
const cardExpirationMessage = (err, field) => {
  const fieldName = getFieldName(field);
  return `${fieldName} is invalid`;
};
const cardCvcMessage = (err, field) => {
  const fieldName = getFieldName(field);
  return `${fieldName} is invalid`;
};
const LinqFormlyValidators = {
  required: 'required',
  minlength: 'minlength',
  maxlength: 'maxlength',
  min: 'min',
  max: 'max',
  minTime: 'minTime',
  maxTime: 'maxTime',
  matDatepickerMin: 'matDatepickerMin',
  matDatepickerMax: 'matDatepickerMax',
  mask: 'mask',
  match: 'match',
  email: 'email',
  duplicateRecord: 'duplicate_record',
  url: 'url',
  minAge: 'minAge',
  requiredTrue: 'requiredTrue',
  api: 'api',
  cardNumber: 'cardNumber',
  cardExpiration: 'cardExpiration',
  cardCvc: 'cardCvc'
};
const validators = [{
  name: LinqFormlyValidators.match,
  validation: matchValidator,
  options: {
    fields: []
  }
}, {
  name: LinqFormlyValidators.email,
  validation: Validators.email
}, {
  name: LinqFormlyValidators.url,
  validation: urlValidator
}, {
  name: LinqFormlyValidators.minAge,
  validation: minAgeValidator
}, {
  name: LinqFormlyValidators.requiredTrue,
  validation: Validators.requiredTrue
}, {
  name: LinqFormlyValidators.cardNumber,
  validation: cardNumberValidator
}, {
  name: LinqFormlyValidators.cardExpiration,
  validation: cardExpirationValidator
}, {
  name: LinqFormlyValidators.cardCvc,
  validation: cardCvcValidator
}];
const validationMessages = [{
  name: LinqFormlyValidators.required,
  message: requiredValidationMessage
}, {
  name: LinqFormlyValidators.minlength,
  message: minLengthValidationMessage
}, {
  name: LinqFormlyValidators.maxlength,
  message: maxLengthValidationMessage
}, {
  name: LinqFormlyValidators.min,
  message: minValidationMessage
}, {
  name: LinqFormlyValidators.max,
  message: maxValidationMessage
}, {
  name: LinqFormlyValidators.minTime,
  message: minTimeValidationMessage
}, {
  name: LinqFormlyValidators.maxTime,
  message: maxTimeValidationMessage
}, {
  name: LinqFormlyValidators.matDatepickerMin,
  message: minDateValidationMessage
}, {
  name: LinqFormlyValidators.matDatepickerMax,
  message: maxDateValidationMessage
}, {
  name: LinqFormlyValidators.mask,
  message: maskValidationMessage
}, {
  name: LinqFormlyValidators.match,
  message: matchMessage
}, {
  name: LinqFormlyValidators.email,
  message: emailMessage
}, {
  name: LinqFormlyValidators.url,
  message: urlMessage
}, {
  name: LinqFormlyValidators.minAge,
  message: minAgeMessage
}, {
  name: LinqFormlyValidators.duplicateRecord,
  message: duplicateRecordMessage
}, {
  name: LinqFormlyValidators.api,
  message: apiErrorMessage
}, {
  name: LinqFormlyValidators.requiredTrue,
  message: requiredTrueMessage
}, {
  name: LinqFormlyValidators.cardNumber,
  message: cardNumberMessage
}, {
  name: LinqFormlyValidators.cardExpiration,
  message: cardExpirationMessage
}, {
  name: LinqFormlyValidators.cardCvc,
  message: cardCvcMessage
}];
class LinqFormlyAccordionWrapperComponent extends FieldWrapper {
  expansionPanels;
  ngAfterViewInit() {
    this.field.fieldGroup?.forEach((field, index) => {
      field.props = {
        ...field.props,
        ref: this.expansionPanels.get(index)
      };
    });
  }
  static ɵfac = /* @__PURE__ */(() => {
    let ɵLinqFormlyAccordionWrapperComponent_BaseFactory;
    return function LinqFormlyAccordionWrapperComponent_Factory(__ngFactoryType__) {
      return (ɵLinqFormlyAccordionWrapperComponent_BaseFactory || (ɵLinqFormlyAccordionWrapperComponent_BaseFactory = i0.ɵɵgetInheritedFactory(LinqFormlyAccordionWrapperComponent)))(__ngFactoryType__ || LinqFormlyAccordionWrapperComponent);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: LinqFormlyAccordionWrapperComponent,
    selectors: [["linq-formly-wrapper-accordion"]],
    viewQuery: function LinqFormlyAccordionWrapperComponent_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(MatExpansionPanel, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.expansionPanels = _t);
      }
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    decls: 3,
    vars: 0,
    consts: [["multi", ""], [3, "disabled", "expanded"], [3, "field"]],
    template: function LinqFormlyAccordionWrapperComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "mat-accordion", 0);
        i0.ɵɵrepeaterCreate(1, LinqFormlyAccordionWrapperComponent_For_2_Template, 6, 5, "mat-expansion-panel", 1, i0.ɵɵrepeaterTrackByIdentity);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵadvance();
        i0.ɵɵrepeater(ctx.field.fieldGroup);
      }
    },
    dependencies: [i1$1.FormlyField, i2$3.MatAccordion, i2$3.MatExpansionPanel, i2$3.MatExpansionPanelHeader, i2$3.MatExpansionPanelTitle, i2$3.MatExpansionPanelDescription],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinqFormlyAccordionWrapperComponent, [{
    type: Component,
    args: [{
      selector: 'linq-formly-wrapper-accordion',
      template: `
    <mat-accordion multi>
      @for (field of field.fieldGroup; track field) {
      <mat-expansion-panel [disabled]="field.props?.['disabled']" [expanded]="field.props?.['expanded']">
        <mat-expansion-panel-header>
          <mat-panel-title> {{ field.props?.['label'] }} </mat-panel-title>
          @if (field.props?.['description']) {
          <mat-panel-description>
            {{ field.props?.['description'] }}
          </mat-panel-description>
          }
        </mat-expansion-panel-header>
        <formly-field [field]="field"></formly-field>
      </mat-expansion-panel>
      }
    </mat-accordion>
  `
    }]
  }], null, {
    expansionPanels: [{
      type: ViewChildren,
      args: [MatExpansionPanel]
    }]
  });
})();
class LinqFormlyWrapperAddonsComponent extends FieldWrapper {
  matPrefix;
  matSuffix;
  get hasSuffix() {
    return !!this.props['addonRight'];
  }
  get hasPrefix() {
    return !!this.props['addonLeft'];
  }
  ngAfterViewInit() {
    if (this.matPrefix) {
      // Note: for text use `textPrefix` property (only in Angular Material >= 15)
      this.props['prefix'] = this.matPrefix;
    }
    if (this.matSuffix) {
      // Note: for text use `textSuffix` property (only in Angular Material >= 15)
      this.props['suffix'] = this.matSuffix;
    }
  }
  get hasRightClick() {
    return !!this.props['addonRight'].onClick;
  }
  addonRightClick($event) {
    if (this.hasRightClick) {
      $event.preventDefault();
      $event.stopPropagation();
      this.props['addonRight'].onClick(this.props, this, $event);
    }
  }
  get hasLeftClick() {
    return !!this.props['addonLeft'].onClick;
  }
  addonLeftClick($event) {
    if (this.hasLeftClick) {
      $event.preventDefault();
      $event.stopPropagation();
      this.props['addonLeft'].onClick(this.props, this, $event);
    }
  }
  static ɵfac = /* @__PURE__ */(() => {
    let ɵLinqFormlyWrapperAddonsComponent_BaseFactory;
    return function LinqFormlyWrapperAddonsComponent_Factory(__ngFactoryType__) {
      return (ɵLinqFormlyWrapperAddonsComponent_BaseFactory || (ɵLinqFormlyWrapperAddonsComponent_BaseFactory = i0.ɵɵgetInheritedFactory(LinqFormlyWrapperAddonsComponent)))(__ngFactoryType__ || LinqFormlyWrapperAddonsComponent);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: LinqFormlyWrapperAddonsComponent,
    selectors: [["linq-formly-wrapper-addons"]],
    viewQuery: function LinqFormlyWrapperAddonsComponent_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c6, 5);
        i0.ɵɵviewQuery(_c7, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.matPrefix = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.matSuffix = _t.first);
      }
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    decls: 5,
    vars: 1,
    consts: [["fieldComponent", ""], ["matSuffix", ""], ["matPrefix", ""], ["type", "button", "mat-icon-button", "", 3, "matTooltip"], ["type", "button", "mat-icon-button", "", 3, "click", "matTooltip"], [3, "svgIcon"]],
    template: function LinqFormlyWrapperAddonsComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵtemplate(0, LinqFormlyWrapperAddonsComponent_Conditional_0_Template, 2, 0);
        i0.ɵɵelementContainer(1, null, 0);
        i0.ɵɵtemplate(3, LinqFormlyWrapperAddonsComponent_ng_template_3_Template, 1, 1, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
      }
      if (rf & 2) {
        i0.ɵɵconditional(!ctx.hasSuffix ? 0 : -1);
      }
    },
    dependencies: [i2$1.MatIconButton, i2.MatIcon, i3.MatTooltip],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinqFormlyWrapperAddonsComponent, [{
    type: Component,
    args: [{
      selector: 'linq-formly-wrapper-addons',
      template: `
    @if (!hasSuffix) {
    <ng-template #matPrefix>
      @if (hasPrefix) { @if (props['addonLeft'].icon || props['addonLeft'].svgIcon || props['addonLeft'].tooltip) {
      <button type="button" [matTooltip]="props['addonLeft'].tooltip" mat-icon-button (click)="addonLeftClick($event)">
        @if (props['addonLeft'].svgIcon) {
        <mat-icon [svgIcon]="props['addonLeft'].svgIcon"> </mat-icon>
        } @else {
        <mat-icon>
          {{ props['addonLeft'].tooltip && !props['addonLeft'].icon ? 'info' : props['addonLeft'].icon }}
        </mat-icon>
        }
      </button>
      } @if (props['addonLeft'].text) {
      <span>{{ props['addonLeft'].text }}</span>
      } }
    </ng-template>
    }
    <ng-container #fieldComponent></ng-container>
    <ng-template #matSuffix>
      @if (props['addonRight']) { @if (props['addonRight'].icon || props['addonRight'].svgIcon ||
      props['addonRight'].tooltip) {
      <button
        type="button"
        [matTooltip]="props['addonRight'].tooltip"
        mat-icon-button
        (click)="addonRightClick($event)"
      >
        @if (props['addonRight'].svgIcon) {
        <mat-icon [svgIcon]="props['addonRight'].svgIcon"> </mat-icon>
        } @else {
        <mat-icon>{{
          props['addonRight'].tooltip && !props['addonRight'].icon ? 'info' : props['addonRight'].icon
        }}</mat-icon>
        }
      </button>
      } @if (props['addonRight'].text) {
      <span>{{ props['addonRight'].text }}</span>
      } }
    </ng-template>
  `
    }]
  }], null, {
    matPrefix: [{
      type: ViewChild,
      args: ['matPrefix']
    }],
    matSuffix: [{
      type: ViewChild,
      args: ['matSuffix']
    }]
  });
})();
class LinqFormlyCardWrapperComponent extends FieldWrapper {
  static ɵfac = /* @__PURE__ */(() => {
    let ɵLinqFormlyCardWrapperComponent_BaseFactory;
    return function LinqFormlyCardWrapperComponent_Factory(__ngFactoryType__) {
      return (ɵLinqFormlyCardWrapperComponent_BaseFactory || (ɵLinqFormlyCardWrapperComponent_BaseFactory = i0.ɵɵgetInheritedFactory(LinqFormlyCardWrapperComponent)))(__ngFactoryType__ || LinqFormlyCardWrapperComponent);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: LinqFormlyCardWrapperComponent,
    selectors: [["linq-formly-wrapper-panel"]],
    features: [i0.ɵɵInheritDefinitionFeature],
    decls: 10,
    vars: 2,
    consts: [["fieldComponent", ""], [1, "linq-card"], [1, "card-header"]],
    template: function LinqFormlyCardWrapperComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "mat-card", 1)(1, "mat-card-header")(2, "div", 2)(3, "mat-card-title");
        i0.ɵɵtext(4);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(5, "mat-card-subtitle");
        i0.ɵɵtext(6);
        i0.ɵɵelementEnd()()();
        i0.ɵɵelementStart(7, "mat-card-content");
        i0.ɵɵelementContainer(8, null, 0);
        i0.ɵɵelementEnd()();
      }
      if (rf & 2) {
        i0.ɵɵadvance(4);
        i0.ɵɵtextInterpolate(ctx.props.title);
        i0.ɵɵadvance(2);
        i0.ɵɵtextInterpolate(ctx.props.subtitle);
      }
    },
    dependencies: [i1$3.MatCard, i1$3.MatCardContent, i1$3.MatCardHeader, i1$3.MatCardSubtitle, i1$3.MatCardTitle],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinqFormlyCardWrapperComponent, [{
    type: Component,
    args: [{
      selector: 'linq-formly-wrapper-panel',
      template: `
    <mat-card class="linq-card">
      <mat-card-header>
        <div class="card-header">
          <mat-card-title>{{ props.title }}</mat-card-title>
          <mat-card-subtitle>{{ props.subtitle }}</mat-card-subtitle>
        </div>
      </mat-card-header>
      <mat-card-content>
        <ng-container #fieldComponent></ng-container>
      </mat-card-content>
    </mat-card>
  `
    }]
  }], null, null);
})();
const CreditCardTypes = {
  Visa: 'visa',
  Mastercard: 'mastercard',
  AmericanExpress: 'american-express',
  Discover: 'discover'
};
const creditCardIcons = [CreditCardTypes.Visa, CreditCardTypes.Mastercard, CreditCardTypes.AmericanExpress, CreditCardTypes.Discover];
const LINQ_FORMLY_CONFIG = FORMLY_CONFIG;
const LinqFormlyExtensions = {
  addons: 'addons'
};
const LinqFormlyWrappers = {
  accordion: 'accordion',
  addons: 'addons',
  card: 'card',
  formField: 'form-field'
};
const LinqFormlyTypes = {
  // default
  input: 'input',
  select: 'select',
  textarea: 'textarea',
  checkbox: 'checkbox',
  radio: 'radio',
  datepicker: 'datepicker',
  // custom
  hr: 'hr',
  button: 'button',
  stepper: 'stepper',
  repeat: 'repeat',
  fileUpload: 'file-upload',
  maskedInput: 'masked-input',
  decimal2Input: 'decimal2-input',
  dollarInput: 'dollar-input',
  zipInput: 'zip-input',
  hiddenMaskedInput: 'hidden-masked-input',
  ssnInput: 'ssn-input',
  passwordInput: 'password-input',
  phoneInput: 'phone-input',
  emailInput: 'email-input',
  minAgeDatepicker: 'min-age-datepicker',
  integerInput: 'integer-input',
  cardNumber: 'card-number',
  cardExpiration: 'card-expiration',
  cardCvc: 'card-cvc'
};
const LinqNgxMaskPatterns = {
  // default
  patterns: {
    '0': {
      pattern: new RegExp('\\d')
    },
    '9': {
      pattern: new RegExp('\\d'),
      optional: true
    },
    X: {
      pattern: new RegExp('\\d'),
      symbol: '*'
    },
    A: {
      pattern: new RegExp('[a-zA-Z0-9]')
    },
    S: {
      pattern: new RegExp('[a-zA-Z]')
    },
    U: {
      pattern: new RegExp('[A-Z]')
    },
    L: {
      pattern: new RegExp('[a-z]')
    },
    d: {
      pattern: new RegExp('\\d')
    },
    m: {
      pattern: new RegExp('\\d')
    },
    M: {
      pattern: new RegExp('\\d')
    },
    H: {
      pattern: new RegExp('\\d')
    },
    h: {
      pattern: new RegExp('\\d')
    },
    s: {
      pattern: new RegExp('\\d')
    },
    // custom
    a: {
      pattern: new RegExp('[a-z0-9]') // lowercase alphanumeric
    }
  }
};
const LinqFormlyConfig = {
  wrappers: [{
    name: LinqFormlyWrappers.accordion,
    component: LinqFormlyAccordionWrapperComponent
  }, {
    name: LinqFormlyWrappers.addons,
    component: LinqFormlyWrapperAddonsComponent
  }, {
    name: LinqFormlyWrappers.card,
    component: LinqFormlyCardWrapperComponent
  }],
  extensions: [{
    name: LinqFormlyExtensions.addons,
    extension: {
      onPopulate: addonsExtension
    }
  }],
  types: [{
    name: LinqFormlyTypes.button,
    component: LinqFormlyButtonComponent
  }, {
    name: LinqFormlyTypes.hr,
    component: LinqFormlyHrComponent
  }, {
    name: LinqFormlyTypes.stepper,
    component: LinqFormlyStepperComponent
  }, {
    name: LinqFormlyTypes.repeat,
    component: LinqFormlyRepeatComponent
  }, {
    name: LinqFormlyTypes.fileUpload,
    component: LinqFormlyFileUploadComponent
  }, {
    name: LinqFormlyTypes.maskedInput,
    component: LinqFormlyMaskedInputComponent,
    wrappers: [LinqFormlyWrappers.formField]
  }, {
    name: LinqFormlyTypes.decimal2Input,
    extends: LinqFormlyTypes.maskedInput,
    defaultOptions: {
      props: {
        mask: Masks.Decimal2,
        dropSpecialCharacters: true
      }
    }
  }, {
    name: LinqFormlyTypes.dollarInput,
    extends: LinqFormlyTypes.decimal2Input,
    defaultOptions: {
      props: {
        mask: Masks.Decimal2,
        dropSpecialCharacters: true,
        addonLeft: {
          icon: 'attach_money'
        }
      }
    }
  }, {
    name: LinqFormlyTypes.zipInput,
    extends: LinqFormlyTypes.maskedInput,
    defaultOptions: {
      props: {
        mask: Masks.Zip,
        maxLength: 10,
        dropSpecialCharacters: false
      }
    }
  }, {
    name: LinqFormlyTypes.hiddenMaskedInput,
    extends: LinqFormlyTypes.maskedInput,
    defaultOptions: {
      props: {
        hiddenInput: true,
        addonRight: {
          icon: 'visibility',
          onClick: props => {
            props.hiddenInput = !props.hiddenInput;
            props.addonRight.icon = props.hiddenInput ? 'visibility' : 'visibility_off';
          }
        }
      }
    }
  }, {
    name: LinqFormlyTypes.ssnInput,
    extends: LinqFormlyTypes.hiddenMaskedInput,
    defaultOptions: {
      props: {
        mask: Masks.SSN,
        dropSpecialCharacters: true
      }
    }
  }, {
    name: LinqFormlyTypes.passwordInput,
    extends: LinqFormlyTypes.input,
    defaultOptions: {
      props: {
        type: 'password',
        addonRight: {
          icon: 'visibility',
          onClick: props => {
            props.type = props.type === 'password' ? 'text' : 'password';
            props.addonRight.icon = props.type === 'password' ? 'visibility' : 'visibility_off';
          }
        }
      }
    }
  }, {
    name: LinqFormlyTypes.phoneInput,
    extends: LinqFormlyTypes.maskedInput,
    defaultOptions: {
      props: {
        mask: Masks.Phone,
        specialCharacters: ['e', 'x', 't', ' ', '(', ')', '-', '.']
      }
    }
  }, {
    name: LinqFormlyTypes.emailInput,
    extends: LinqFormlyTypes.input,
    defaultOptions: {
      props: {
        dropSpecialCharacters: false
      },
      validators: {
        validation: [{
          name: 'email'
        }]
      }
    }
  }, {
    name: LinqFormlyTypes.minAgeDatepicker,
    extends: LinqFormlyTypes.datepicker,
    defaultOptions: {
      props: {
        datepickerOptions: {
          max: new Date()
        },
        minAge: 18
      },
      validators: {
        validation: ['minAge']
      }
    }
  }, {
    name: LinqFormlyTypes.integerInput,
    extends: LinqFormlyTypes.maskedInput,
    defaultOptions: {
      props: {
        mask: '9*'
      }
    }
  }, {
    name: LinqFormlyTypes.cardExpiration,
    extends: LinqFormlyTypes.maskedInput,
    defaultOptions: {
      props: {
        mask: Masks.CreditCard.expiration,
        dropSpecialCharacters: true,
        label: 'Expiration'
      },
      validators: {
        validation: [LinqFormlyValidators.cardExpiration]
      }
    }
  }, {
    name: LinqFormlyTypes.cardCvc,
    extends: LinqFormlyTypes.maskedInput,
    defaultOptions: {
      props: {
        mask: Masks.CreditCard.cvc,
        dropSpecialCharacters: true,
        label: 'CVC'
      },
      validators: {
        validation: [LinqFormlyValidators.cardCvc]
      }
    }
  }, {
    name: LinqFormlyTypes.cardNumber,
    extends: LinqFormlyTypes.maskedInput,
    defaultOptions: {
      expressions: {
        'props.addonLeft': field => {
          const value = field.formControl?.value;
          const validation = CreditCardValidator.number(value);
          const cardType = validation.card?.type;
          if (creditCardIcons.includes(cardType)) {
            return {
              svgIcon: cardType
            };
          }
          return {
            icon: 'credit_card'
          };
        }
      },
      props: {
        label: 'Card Number',
        supportedCards: [CreditCardTypes.Visa, CreditCardTypes.Mastercard, CreditCardTypes.AmericanExpress, CreditCardTypes.Discover],
        addonLeft: {
          icon: 'credit_card'
        },
        mask: new BehaviorSubject(Masks.CreditCard.visa),
        keydown: field => {
          const value = field.formControl?.value;
          const validation = CreditCardValidator.number(value);
          const cardType = validation.card?.type || 'visa';
          const mask = Masks.CreditCard[cardType];
          if (field.props?.mask instanceof BehaviorSubject) {
            field.props.mask.next(mask);
          }
        }
      },
      validators: {
        validation: [LinqFormlyValidators.cardNumber]
      }
    }
  }],
  validationMessages,
  validators
};
const materialModules = [MatButtonModule, MatStepperModule, MatAccordion, MatCardModule, MatDatepickerModule, MatExpansionModule, MatIconModule, MatInputModule, MatTooltipModule, MatFormFieldModule];
class LinqFormlyModule {
  constructor() {
    try {
      const iconRegistry = inject(MatIconRegistry);
      const sanitizer = inject(DomSanitizer);
      creditCardIcons.forEach(icon => {
        iconRegistry.addSvgIcon(icon, sanitizer.bypassSecurityTrustResourceUrl(`./assets/credit-cards/${icon}.svg`));
      });
    } catch (ex) {
      console.error('Error loading credit card logos', ex);
    }
  }
  static for(configuration) {
    return {
      ngModule: LinqFormlyModule,
      providers: [{
        provide: FORMLY_CONFIG,
        useValue: configuration,
        multi: true
      }]
    };
  }
  static forRoot(configuration) {
    return this.for(configuration);
  }
  static forChild(configuration) {
    return this.for(configuration);
  }
  static ɵfac = function LinqFormlyModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || LinqFormlyModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: LinqFormlyModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [{
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        subscriptSizing: 'dynamic'
      }
    }, {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {
        showError: true
      }
    }, provideNativeDateAdapter(), provideNgxMask(LinqNgxMaskPatterns), provideHttpClient()],
    imports: [CommonModule, ReactiveFormsModule, MatProgressBarModule, LinqFileUploadModule, MatDatepickerModule, FormlyMaterialModule, FormlyModule.forRoot(LinqFormlyConfig), MatButtonModule, MatStepperModule, MatCardModule, MatDatepickerModule, MatExpansionModule, MatIconModule, MatInputModule, MatTooltipModule, MatFormFieldModule, FormlyModule, FormlyMaterialModule, FormlyMatDatepickerModule, ReactiveFormsModule, MatProgressBarModule, LinqFileUploadModule, MatButtonModule, MatStepperModule, MatCardModule, MatDatepickerModule, MatExpansionModule, MatIconModule, MatInputModule, MatTooltipModule, MatFormFieldModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinqFormlyModule, [{
    type: NgModule,
    args: [{
      declarations: [LinqFormlyStepperComponent, LinqFormlyAccordionWrapperComponent, LinqFormlyFileUploadComponent, LinqFormlyRepeatComponent, LinqFormlyWrapperAddonsComponent, LinqFormlyCardWrapperComponent, LinqFormlyMaskedInputComponent, LinqFormlyValidationMessagesComponent, LinqFormlyComponent, LinqFormlyButtonComponent, LinqFormlyHrComponent],
      providers: [{
        provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
        useValue: {
          appearance: 'outline',
          subscriptSizing: 'dynamic'
        }
      }, {
        provide: STEPPER_GLOBAL_OPTIONS,
        useValue: {
          showError: true
        }
      }, provideNativeDateAdapter(), provideNgxMask(LinqNgxMaskPatterns), provideHttpClient()],
      imports: [CommonModule, ReactiveFormsModule, NgxMaskDirective, NgxMaskPipe, MatProgressBarModule, LinqFileUploadModule, MatDatepickerModule, FormlyMaterialModule, FormlyModule.forRoot(LinqFormlyConfig), ...materialModules],
      exports: [FormlyModule, FormlyMaterialModule, FormlyMatDatepickerModule, ReactiveFormsModule, MatProgressBarModule, LinqFileUploadModule, NgxMaskDirective, NgxMaskPipe, LinqFormlyValidationMessagesComponent, LinqFormlyComponent, ...materialModules]
    }]
  }], () => [], null);
})();

/*
 * Public API Surface of formly
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CreditCardTypes, Form, LINQ_FORMLY_CONFIG, LinqFileUploadComponent, LinqFileUploadModule, LinqFormlyComponent, LinqFormlyConfig, LinqFormlyExtensions, LinqFormlyModule, LinqFormlyTypes, LinqFormlyValidationMessagesComponent, LinqFormlyValidators, LinqFormlyWrappers, LinqNgxMaskPatterns, MAX_FILES, MAX_FILE_SIZE, MB, apiErrorMessage, calculatePath, cardCvcMessage, cardCvcValidator, cardExpirationMessage, cardExpirationValidator, cardNumberMessage, cardNumberValidator, defaultValidators, duplicateRecordMessage, emailMessage, expandPanel, filterByAllowedExtensions, filterByMaxFiles, filterByMaxSize, findStep, findStepper, findStepperField, findStepperSteps, focusField, getFieldName, hasBegEndWhitespaceMessage, maskValidationMessage, matchMessage, matchValidator, maxDateValidationMessage, maxDecimalPlacesValidationMessage, maxLengthValidationMessage, maxTimeValidationMessage, maxValidationMessage, minAgeMessage, minAgeValidator, minDateValidationMessage, minLengthValidationMessage, minTimeValidationMessage, minValidationMessage, requiredTrueMessage, requiredValidationMessage, setStep, urlMessage, urlValidator, validationMessages, validators };
