import { Component, inject, NgModule } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatListModule } from '@angular/material/list';
import { FormlyFormOptions, FormlyFieldConfig, FieldWrapper } from '@ngx-formly/core';
import { CommonModule } from '@angular/common';
import { Masks, UsernameMaxLength } from '@app/constants';
import { StateOptions, CountryOptions, MAX_FILE_SIZE, MAX_FILES } from '@shared/constants';
import { BankAccountTypeEnum, BusinessTypeEnum } from '@app/enums';
import { MatCardModule } from '@angular/material/card';
import { environment } from '@environments/environment';
import { Router } from '@angular/router';
import { LinqPayService } from '@app/service';
import { getFormlyFieldKey } from '@app/constants/vendors/payrix';
import { PartialDeep } from 'type-fest';
import _ from 'lodash';
import { catchError, concat, map, of, retry, switchMap } from 'rxjs';
import { ModelError, PostMerchantRequest, PostMerchantResponse } from '@linq/pay-js';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  LinqFormlyModule,
  ValidationMessage,
  LinqFormlyTypes,
  expandPanel,
  focusField,
  Form,
  setStep,
  LinqFormlyValidators,
} from '@linq/formly';
import { MatIconModule } from '@angular/material/icon';
import { validators, validationMessages } from '@app/utils/formly-validation';

declare global {
  interface Window {
    Plaid: any;
  }
}

const uploadAllowedExtensions = ['.jpg', '.jpeg', '.png', '.pdf', '.tif', '.tiff'];

const initialValue: PartialDeep<PostMerchantRequest> = {
  business: {
    address: {},
    account: {},
  },
  contact: {
    sameAsBusinessNumber: false,
    sameAsBusinessEmail: false,
  },
  banking: {},
  terms: {
    agree: false,
    attest: false,
  },
};

const getInitialValue = () => {
  return JSON.parse(JSON.stringify(initialValue));
};

@NgModule({
  imports: [
    LinqFormlyModule.forChild({
      validators,
      validationMessages,
    }),
  ],
  exports: [LinqFormlyModule],
})
export class FormsModule {}

@Component({
  selector: 'app-merchant-application',
  standalone: true,
  imports: [FormsModule, CommonModule, MatCardModule, MatIconModule, MatListModule, MatProgressSpinnerModule],
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss'],
})
export class MerchantApplicationComponent {
  router = inject(Router);
  linqPayService = inject(LinqPayService);

  form = new FormGroup({});

  model: PostMerchantRequest = getInitialValue();

  options: FormlyFormOptions = {
    formState: {
      openedTerms: false,
      openedDisclosure: false,
    },
  };

  errorMessages = new Map<string, ValidationMessage>();

  async onErrorMessageClick(errorMessage: ValidationMessage) {
    const field = errorMessage.field;
    await setStep(field);
    await expandPanel(field);
    await focusField(field);
  }

  submitted = false;
  loading = false;

  setApiErrors(errors: ModelError[]) {
    errors.forEach((error) => {
      if (error.field) {
        const formlyFieldName = getFormlyFieldKey(error.field);
        const formControl = this.form.get(formlyFieldName);
        if (formControl) {
          formControl.setErrors({ api: error });
        }
      }
    });
  }

  linkWithPlaid(linkToken: string | undefined): Promise<{ linkToken: string | undefined; metadata: any }> {
    return new Promise((resolve, reject) => {
      const plaidTokenRequest = {
        userId: `linq-merchant-application-${Date.now()}`,
        countryCode: 'US',
        redirectUri: environment.redirectUri,
      };

      of(linkToken)
        .pipe(
          switchMap((linkToken) => {
            if (linkToken) {
              return of(linkToken);
            }
            return this.linqPayService
              .postPlaidLink(plaidTokenRequest)
              .pipe(map((response) => response.data?.[0].linkToken));
          }),
        )
        .subscribe((plaidLinkToken: string | undefined) => {
          if (!plaidLinkToken) {
            reject(new Error('Unable to retrieve link token'));
          }
          const handler = window.Plaid.create({
            token: plaidLinkToken,
            onSuccess: (_: string, metadata: any) => {
              resolve({ linkToken: plaidLinkToken, metadata });
            },
            onExit: (error: any, metadata: any) => {
              if (error != null) {
                reject(new Error(error));
              } else {
                resolve({ linkToken: plaidLinkToken, metadata });
              }
            },
          });
          handler.open();
        });
    });
  }

  postMerchant(model: PostMerchantRequest) {
    this.loading = true;

    this.linqPayService
      .postMerchant(model)
      .subscribe({
        next: (response) => {
          const errors = response.errors;
          if (errors?.length) {
            this.setApiErrors(errors);
          }
          const notes = _.get(response, 'data[0].entities[0].notes', []);
          const noteDocuments = notes.map((note) => note.noteDocuments || []).flat();

          if (noteDocuments.length) {
            this.postDocuments(response);
          } else {
            this.router.navigateByUrl('/submitted', {
              replaceUrl: true,
            });
          }
        },
        error: (error) => {
          const errors = error?.response?.data?.errors;
          if (errors?.length) {
            this.setApiErrors(errors);
          }
        },
      })
      .add(() => {
        this.loading = false;
      });
  }

  postDocuments(response: PostMerchantResponse) {
    const notes = _.get(response, 'data[0].entities[0].notes', []);
    const noteDocuments = notes.map((note) => note.noteDocuments || []).flat();

    const uploadDocumentRequests = noteDocuments.map((noteDocument: any) => {
      // use the custom field to find the file in the model
      const file = _.get(this.model.banking.banks, noteDocument.custom) as File;

      return this.linqPayService.postDocument(noteDocument.id, file).pipe(
        retry(3),
        catchError((error) => of(error.response?.data)),
      );
    });

    const documentUploadErrors: ModelError[] = [];
    _.defer(() => (this.loading = true));
    return concat(...uploadDocumentRequests)
      .pipe(catchError((error) => of(error.response?.data)))
      .subscribe((documentResponse) => {
        if (documentResponse.errors?.length) {
          documentUploadErrors.push(...documentResponse.errors);
          return;
        }
        const noteDocument = documentResponse.data[0];
        const documentUploadError = noteDocument.status !== 'processed';
        if (documentUploadError) {
          documentUploadErrors.push({
            field: `banking.banks.${noteDocument.custom}`,
            message: 'Document upload failed',
          });
        }
      })
      .add(() => {
        this.loading = false;

        if (documentUploadErrors.length) {
          this.setApiErrors(documentUploadErrors);
        }

        this.router.navigateByUrl('/submitted', {
          replaceUrl: true,
        });
      });
  }

  submit() {
    this.submitted = true;
    this.postMerchant(this.model);
  }

  onErrorMessages(errorMessages: Map<string, ValidationMessage>) {
    this.errorMessages = errorMessages;
  }

  fields: FormlyFieldConfig[] = [
    {
      type: LinqFormlyTypes.stepper,
      fieldGroup: [
        {
          props: { label: 'School Information' },
          key: 'business',
          wrappers: ['accordion'],
          fieldGroup: [
            {
              fieldGroupClassName: 'row',
              props: {
                label: 'School Overview',
                description:
                  'General school information related to type, description, contact information, and general payment information go here',
                expanded: true,
              },
              fieldGroup: [
                {
                  key: 'country',
                  type: 'select',
                  className: 'col-6 col-lg-2',
                  defaultValue: 'USA',
                  props: {
                    label: 'Country',
                    required: true,
                    options: CountryOptions,
                  },
                },
                {
                  key: 'businessType',
                  type: 'select',
                  className: 'col-6 col-lg-2',
                  props: {
                    label: 'School Type',
                    required: true,
                    options: [
                      {
                        value: BusinessTypeEnum.GOV,
                        label: 'GOV',
                      },
                      {
                        value: BusinessTypeEnum.NONPROFIT,
                        label: 'NONPROFIT',
                      },
                    ],
                  },
                },
                {
                  key: 'legalBusinessName',
                  type: 'input',
                  className: 'col-6 col-lg-4',
                  props: {
                    label: 'Legal School Name',
                    required: true,
                    addonRight: {
                      tooltip: `IRS Legal Filling Name. IRS Filling Name is required if the School Legal Name provided is greater than 24 characters. Must match what was provided to IRS.`,
                    },
                  },
                  expressions: {
                    hide: '!model.businessType',
                  },
                },
                {
                  key: 'dba',
                  type: 'input',
                  className: 'col-6 col-lg-4',
                  props: {
                    label: 'DBA - Statement Descriptor',
                    addonRight: {
                      tooltip: `This descriptor is how the merchant's business name will appear on customer credit card statements. Must contain at least 1 letter. Letters will be ALL CAPS. Special characters & . , are permitted`,
                    },
                    minLength: 5,
                    maxLength: 23,
                  },
                  expressions: {
                    hide: '!model.businessType',
                  },
                },
                {
                  key: 'industry',
                  type: 'select',
                  className: 'col-6 col-lg-4',
                  defaultValue: '9399',
                  props: {
                    label: 'School Type',
                    required: true,
                    options: [
                      {
                        value: '9399',
                        label: '9399 - Government Services',
                      },
                    ],
                  },
                  expressions: {
                    hide: '!model.businessType',
                  },
                },
                {
                  key: 'tinType',
                  type: 'select',
                  className: 'col-6 col-lg-4',
                  defaultValue: 'tin',
                  props: {
                    label: 'TIN Type',
                    required: true,
                    options: [
                      {
                        value: 'tin',
                        label: 'EIN',
                      },
                    ],
                  },
                  expressions: {
                    hide: '!model.businessType',
                  },
                },
                {
                  key: 'tin',
                  type: 'hidden-masked-input',
                  className: 'col-6 col-lg-4',
                  props: {
                    label: 'EIN',
                    required: true,
                    hiddenInput: true,
                    addonRight: {
                      tooltip: `The 9-digit business identification code as issued by the IRS. If this business is a sole proprietorship, leave this field empty if no EIN was issued.`,
                    },
                    mask: Masks.EIN,
                  },
                  expressions: {
                    hide: '!model.businessType',
                  },
                },
                {
                  key: 'businessPhone',
                  type: 'phone-input',
                  className: 'col-6 col-lg-3',
                  props: {
                    label: 'School Phone',
                    required: true,
                  },
                  expressions: {
                    hide: '!model.businessType',
                  },
                },
                {
                  key: 'businessEmail',
                  type: 'email-input',
                  className: 'col-6 col-lg-3',
                  props: {
                    required: true,
                    label: 'School Email',
                  },
                  expressions: {
                    hide: '!model.businessType',
                  },
                },
                {
                  key: 'website',
                  type: 'input',
                  className: 'col-6 col-lg-3',
                  props: {
                    label: 'School Website',
                    required: true,
                    addonRight: {
                      tooltip: 'Enter your school website with the following format: https://linq.com',
                    },
                    placeholder: 'https://linq.com',
                  },
                  expressions: {
                    hide: '!model.businessType',
                  },
                  validators: {
                    validation: [LinqFormlyValidators.url],
                  },
                },
                {
                  key: 'customerServicePhone',
                  type: 'phone-input',
                  className: 'col-6 col-lg-3',
                  props: {
                    label: 'Customer Service Phone',
                    required: true,
                  },
                  expressions: {
                    hide: '!model.businessType',
                  },
                },
                {
                  key: 'environment',
                  type: 'select',
                  className: 'col-12 col-lg-6',
                  props: {
                    label: 'How does the school primarily take payments?',
                    errorLabel: 'Primary Payment Method',
                    required: true,
                    options: [
                      {
                        value: 'cardPresent',
                        label: 'Merchant Accepts Card In Person',
                      },
                      {
                        value: 'ecommerce',
                        label: 'Customer uses LINQ Connect',
                      },
                      {
                        value: 'moto',
                        label: 'Merchant Manually Keys Card Information',
                      },
                    ],
                  },
                  expressions: {
                    hide: '!model.businessType',
                  },
                },
              ],
            },
            {
              props: {
                label: 'School Details',
                description: 'Provide information regarding your banking, payment processing, and transactions.',
              },
              fieldGroupClassName: 'row',
              fieldGroup: [
                {
                  key: 'bankStatementDescription',
                  type: 'input',
                  className: 'col-4',
                  props: {
                    label: 'Description for your bank statement',
                    maxLength: 15,
                  },
                },
                {
                  key: 'description',
                  type: 'textarea',
                  className: 'col-8',
                  props: {
                    errorLabel: 'Description',
                    label: 'What does your school process payments for, and how are goods sold?',
                    autosize: true,
                    autosizeMaxRows: 3,
                    autosizeMinRows: 1,
                    required: true,
                    description: 'Describe the goods and services you commonly provide here.',
                  },
                },
                {
                  key: 'established',
                  type: 'datepicker',
                  className: 'col-4',
                  props: {
                    label: 'Date Established',
                    required: true,
                    datepickerOptions: {
                      max: new Date(),
                    },
                  },
                },
                {
                  key: 'annualVolume',
                  type: 'dollar-input',
                  className: 'col-4',
                  props: {
                    label: 'Annual Processing Volume (USD Dollars USD)',
                    errorLabel: 'Annual Processing Volume',
                    description: 'The amount of sales you expect to process in a year',
                    required: true,
                  },
                },
                {
                  key: 'averageVolume',
                  type: 'dollar-input',
                  className: 'col-4',
                  props: {
                    label: 'Average Transaction Amount (USD Dollars USD)',
                    errorLabel: 'Average Transaction Amount',
                    description: 'Average sale amount in the previous year',
                    required: true,
                  },
                },
              ],
            },
            {
              key: 'address',
              props: {
                label: 'School Address',
                description: 'Provide the best address for your school.',
              },
              fieldGroupClassName: 'row',
              fieldGroup: [
                {
                  key: 'address1',
                  className: 'col-6',
                  type: 'input',
                  props: {
                    label: 'Address',
                    description: 'No PO Box addresses accepted',
                    required: true,
                  },
                },
                {
                  key: 'address2',
                  className: 'col-6',
                  type: 'input',
                  props: {
                    label: 'Address Line 2',
                  },
                },
                {
                  key: 'city',
                  type: 'input',
                  className: 'col-6',
                  props: {
                    label: 'City',
                    required: true,
                  },
                },
                {
                  key: 'state',
                  type: 'select',
                  className: 'col-3',
                  props: {
                    label: 'State',
                    required: true,
                    options: StateOptions,
                  },
                },
                {
                  key: 'zip',
                  type: 'zip-input',
                  className: 'col-3',
                  props: {
                    label: 'ZIP',
                    required: true,
                  },
                },
              ],
            },
          ],
        },
        {
          props: { label: 'Point of Contact Information' },
          key: 'contact',
          wrappers: ['accordion'],
          fieldGroup: [
            {
              props: {
                label: 'Disclaimer',
                expanded: true,
              },
              fieldGroupClassName: 'row',
              fieldGroup: [
                {
                  template: `
                  <p>
                    To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each individual or business who opens an account. What this means for you: when you open an account, we will ask for your identifiable information including your full name, address, date of birth, and other business information that will allow us to identify you. We may also ask to see your Identification Card, Driver’s License, and/or other identifying documents. The following information must be provided for the main point of contact and individual with significant responsibility.
                  </p>
                  `,
                },
              ],
            },
            {
              props: {
                label: 'Point of Contact',
                description: 'Information for Point of Contact',
                expanded: true,
              },
              fieldGroupClassName: 'row',
              fieldGroup: [
                {
                  key: 'firstName',
                  type: 'input',
                  className: 'col-4',
                  props: {
                    label: 'First Name',
                    required: true,
                  },
                },
                {
                  key: 'middleName',
                  className: 'col-4',
                  type: 'input',
                  props: {
                    label: 'Middle Name',
                  },
                },
                {
                  key: 'lastName',
                  className: 'col-4',
                  type: 'input',
                  props: {
                    label: 'Last Name',
                    required: true,
                  },
                },
                {
                  key: 'dob',
                  className: 'col-4',
                  type: 'min-age-datepicker',
                  props: {
                    label: 'Date of Birth',
                    minAge: 18,
                  },
                  expressions: {
                    hide: () => {
                      return this.model.business.businessType !== BusinessTypeEnum.NONPROFIT;
                    },
                    'props.required': () => this.model.business.businessType === BusinessTypeEnum.NONPROFIT,
                  },
                },
                {
                  key: 'ssn',
                  className: 'col-4',
                  type: 'ssn-input',
                  props: {
                    label: 'SSN',
                  },
                  expressions: {
                    hide: () => {
                      return this.model.business.businessType !== BusinessTypeEnum.NONPROFIT;
                    },
                    'props.required': () => this.model.business.businessType === BusinessTypeEnum.NONPROFIT,
                  },
                },
                {
                  className: 'col-8',
                  fieldGroupClassName: 'row',
                  fieldGroup: [
                    {
                      key: 'phoneNumber',
                      className: 'col-6',
                      type: 'phone-input',
                      props: {
                        label: 'Phone Number',
                        required: true,
                      },
                      expressions: {
                        'props.disabled': (field: FormlyFieldConfig) => {
                          return field.model.sameAsBusinessNumber;
                        },
                      },
                    },
                    {
                      key: 'email',
                      type: 'email-input',
                      className: 'col-6',
                      props: {
                        label: 'Email',
                        required: true,
                      },
                      expressions: {
                        'props.disabled': (field: FormlyFieldConfig) => {
                          return field.model.sameAsBusinessEmail;
                        },
                      },
                    },
                    {
                      key: 'sameAsBusinessNumber',
                      type: 'checkbox',
                      className: 'col-6',
                      wrappers: [],
                      props: {
                        label: 'Same as school number',
                        change: (field: FormlyFieldConfig, event: any) => {
                          if (event.checked) {
                            field.form?.get('phoneNumber')?.setValue(this.model.business.businessPhone);
                          }
                        },
                      },
                    },
                    {
                      key: 'sameAsBusinessEmail',
                      type: 'checkbox',
                      className: 'col-6',
                      wrappers: [],
                      props: {
                        label: 'Same as school email',
                        change: (field: FormlyFieldConfig, event: any) => {
                          if (event.checked) {
                            field.form?.get('email')?.setValue(this.model.business.businessEmail);
                          }
                        },
                      },
                    },
                  ],
                },
                {
                  key: 'country',
                  type: 'select',
                  className: 'col-4',
                  defaultValue: 'USA',
                  props: {
                    label: 'Country',
                    required: true,
                    options: CountryOptions,
                  },
                },

                {
                  key: 'businessTitle',
                  type: 'input',
                  className: 'col-4',
                  props: {
                    label: 'School Title',
                    required: true,
                  },
                },
                Form.Br(),
                {
                  key: 'address1',
                  type: 'input',
                  className: 'col-6',
                  props: {
                    label: 'Address',
                    description: 'No PO Box addresses accepted',
                    required: true,
                  },
                },
                {
                  key: 'address2',
                  type: 'input',
                  className: 'col-6',
                  props: {
                    label: 'Address Line 2',
                  },
                },
                {
                  key: 'city',
                  type: 'input',
                  className: 'col-6',
                  props: {
                    label: 'City',
                    required: true,
                  },
                },
                {
                  key: 'state',
                  type: 'select',
                  className: 'col-3',
                  props: {
                    label: 'State',
                    required: true,
                    options: StateOptions,
                  },
                },
                {
                  key: 'zip',
                  type: 'zip-input',
                  className: 'col-3',
                  props: {
                    label: 'Zip',
                    required: true,
                  },
                },
              ],
            },
            {
              key: 'account',
              props: {
                label: 'Account Login',
                description: 'Create your account username and email',
              },
              validators: {
                validation: [
                  {
                    name: LinqFormlyValidators.match,
                    options: { fields: ['password', 'confirmPassword'], errorPath: 'confirmPassword' },
                  },
                ],
              },
              fieldGroupClassName: 'row',
              fieldGroup: [
                {
                  key: 'username',
                  type: 'masked-input',
                  className: 'col-6 col-lg-4',
                  props: {
                    label: 'Username',
                    required: true,
                    mask: Masks.Username,
                    validation: false,
                    maxLength: UsernameMaxLength,
                  },
                },
                Form.Br(),
                {
                  key: 'password',
                  type: 'password-input',
                  className: 'col-6 col-lg-4',
                  props: {
                    label: 'Password',
                    required: true,
                    hiddenInput: true,
                  },
                  validators: {
                    validation: [
                      {
                        name: 'password',
                      },
                    ],
                  },
                },
                {
                  key: 'confirmPassword',
                  type: 'password-input',
                  className: 'col-6 col-lg-4',
                  props: {
                    label: 'Confirm Password',
                    required: true,
                  },
                },
                {
                  template:
                    '&bullet; Password must include one number, one upper case letter, and one lower case letter.',
                },
                {
                  template: '&bullet; Password must be at least 8 characters long.',
                },
                {
                  template:
                    '<strong>Please Note: An email confirmation link will be sent to this user. Confirmation of the account is required prior to account activation.',
                },
              ],
            },
          ],
        },
        {
          props: { label: 'Banking Information' },
          key: 'banking',
          wrappers: ['accordion'],
          fieldGroup: [
            {
              props: {
                label: 'Enter Bank Information',
                expanded: true,
              },
              fieldGroup: [
                {
                  type: 'repeat',
                  key: 'banks',
                  defaultValue: [{}],
                  props: {
                    addText: 'Add Another Account',
                    removeText: 'Remove',
                  },
                  fieldArray: {
                    fieldGroupClassName: 'row',
                    validators: {
                      validation: [
                        {
                          name: LinqFormlyValidators.match,
                          options: {
                            fields: ['accountNumber', 'confirmAccountNumber'],
                            errorPath: 'confirmAccountNumber',
                          },
                        },
                      ],
                    },
                    fieldGroup: [
                      {
                        type: 'radio',
                        key: 'manual',
                        className: 'vertical-radio-group',
                        props: {
                          options: [
                            {
                              value: true,
                              label: 'Enter bank information manually',
                            },
                            {
                              value: false,
                              label:
                                'Fastest way to onboard. This form must be submitted within 30 minutes of Plaid verification or you will need to reverify with Plaid.',
                            },
                          ],
                          change: (field, event) => {
                            const isPlaid = !event.value;
                            if (isPlaid) {
                              const linkToken = field.model.linkToken;

                              this.linkWithPlaid(linkToken)
                                .then(({ metadata, linkToken }) => {
                                  if (linkToken) {
                                    field.model.linkToken = linkToken;
                                  }
                                  const account = metadata && metadata.accounts && metadata.accounts[0];
                                  if (account) {
                                    field.model.publicToken = metadata.public_token;
                                    field.model.accountToken = metadata.account_id;
                                    field.form?.get('plaid')?.setValue(`${account.name} - ${account.mask}`);
                                  }
                                  if (!field.model.publicToken && !field.model.accountToken) {
                                    field.form?.get('manual')?.setValue(undefined);
                                  }
                                })
                                .catch(() => {
                                  if (!field.model.publicToken && !field.model.accountToken) {
                                    field.form?.get('manual')?.setValue(undefined);
                                  }
                                });
                            }
                          },
                        },
                        wrappers: [],
                      },
                      {
                        key: 'plaid',
                        type: 'string',
                        className: 'col-6',
                        props: {
                          label: 'Linked Account',
                          disabled: true,
                          addonRight: {
                            icon: 'edit',
                            onClick: (_: any, wrapper: FieldWrapper) => {
                              const field = wrapper.field;

                              const linkToken = field.model.linkToken;

                              this.linkWithPlaid(linkToken)
                                .then(({ metadata }) => {
                                  const account = metadata && metadata.accounts && metadata.accounts[0];
                                  if (account) {
                                    field.model.publicToken = metadata.public_token;
                                    field.model.accountToken = metadata.account_id;
                                    field.form?.get('plaid')?.setValue(`${account.name} - ${account.mask}`);
                                  }
                                  if (!field.model.publicToken && !field.model.accountToken) {
                                    field.form?.get('manual')?.setValue(undefined);
                                  }
                                })
                                .catch(() => {
                                  if (!field.model.publicToken && !field.model.accountToken) {
                                    field.form?.get('manual')?.setValue(undefined);
                                  }
                                });
                            },
                          },
                        },
                        expressions: {
                          hide: (field) => !(field.model?.manual === false),
                          'props.required': (field) => field.model?.manual === false,
                        },
                      },
                      {
                        key: 'accountType',
                        type: 'select',
                        className: 'col-6 col-lg-3',
                        expressions: {
                          hide: (field) => field.model?.manual !== true,
                        },
                        props: {
                          label: 'Account Type',
                          required: true,
                          options: [
                            {
                              value: BankAccountTypeEnum.CHECKING,
                              label: 'Checking',
                            },
                            {
                              value: BankAccountTypeEnum.SAVINGS,
                              label: 'Savings',
                            },
                            {
                              value: BankAccountTypeEnum.CORPORATE_CHECKING,
                              label: 'Business Checking',
                            },
                            {
                              value: BankAccountTypeEnum.CORPORATE_SAVINGS,
                              label: 'Business Savings',
                            },
                          ],
                        },
                      },
                      {
                        key: 'routingNumber',
                        type: 'integer-input',
                        className: 'col-6 col-lg-3',
                        props: {
                          label: 'Bank Routing Number',
                          required: true,
                          addonRight: {
                            tooltip: `Routing number for the business bank account.`,
                          },
                        },
                        expressions: {
                          hide: (field) => field.model?.manual !== true,
                        },
                      },
                      {
                        key: 'accountNumber',
                        type: 'integer-input',
                        className: 'col-6 col-lg-3',
                        props: {
                          label: 'Bank Account Number',
                          required: true,
                          addonRight: {
                            tooltip: `Account number for the business bank account.`,
                          },
                        },
                        expressions: {
                          hide: (field) => field.model?.manual !== true,
                        },
                      },
                      {
                        key: 'confirmAccountNumber',
                        type: 'integer-input',
                        className: 'col-6 col-lg-3',
                        props: {
                          label: 'Confirm Bank Account Number',
                          errorLabel: 'Bank Account Number',
                          required: true,
                          addonRight: {
                            tooltip: `Account number for the business bank account.`,
                          },
                        },
                        expressions: {
                          hide: (field) => field.model?.manual !== true,
                        },
                      },
                      {
                        key: 'description',
                        type: 'input',
                        className: 'col-12',
                        props: {
                          label: 'Description',
                        },
                        expressions: {
                          hide: (field) => field.model?.manual !== true,
                        },
                      },
                      {
                        template: `
                          <p>Upload Bank Letter and/or Voided Check <br />
                          Each document may not exceed 8 MB. Accepted document types: ${uploadAllowedExtensions.join(
                            ' / ',
                          )}
                          </p>
                        `,
                        expressions: {
                          hide: (field) => field.model?.manual !== true,
                        },
                      },
                      {
                        key: 'documents',
                        type: 'file-upload',
                        props: {
                          label: 'File Upload',
                          required: false,
                          maxSize: MAX_FILE_SIZE,
                          allowedExtensions: uploadAllowedExtensions,
                          maxFiles: MAX_FILES,
                        },
                        expressions: {
                          hide: (field) => field.model?.manual !== true,
                        },
                      },
                    ],
                  },
                },
              ],
            },
          ],
        },
        {
          props: { label: 'Terms & Condition' },
          key: 'terms',
          wrappers: ['accordion'],
          fieldGroup: [
            {
              props: {
                label: 'Terms & Condition',
                expanded: true,
              },
              fieldGroup: [
                {
                  template: `
                      <p>Please Review then Print or Save the documents provided in the links below. You must review the documents before you can proceed.</p>
                        By clicking <strong>I Agree</strong>, I hereby confirm that I:
                        <br />
                        Have Read, Agreed and Consented to the <a onclick="onDisclosureClicked()" id="disclosure-link" class="linq-link" target="_blank" href="https://pay.linq.com/bank_disclosures">Bank Disclosure Page (CLICK HERE)</a> provided.
                        <br/>
                        Have Read, Agreed and Consented to the <a onclick="onTermsClicked()" id="terms-link" class="linq-link" target="_blank" href="https://pay.linq.com/terms">Merchant Terms and Conditions (CLICK HERE)</a> provided.
                      </p>
                    `,
                  props: {
                    safeHtml: true,
                  },
                  hooks: {
                    onInit: () => {
                      (window as any).onTermsClicked = () => {
                        this.options.formState.openedTerms = true;
                      };
                      (window as any).onDisclosureClicked = () => {
                        this.options.formState.openedDisclosure = true;
                      };
                    },
                  },
                },
                {
                  type: 'checkbox',
                  key: 'agree',
                  props: {
                    label: 'I Agree to the above information',
                    errorLabel: 'Agree',
                    required: true,
                  },
                  wrappers: [],
                  expressions: {
                    'props.disabled': () => {
                      return !(this.options.formState.openedDisclosure && this.options.formState.openedTerms);
                    },
                  },
                  validators: {
                    validation: [
                      {
                        name: 'requiredTrue',
                      },
                    ],
                  },
                },
                Form.Br(),
                {
                  type: 'checkbox',
                  key: 'attest',
                  props: {
                    required: true,
                    errorLabel: 'Attest',
                    label:
                      'I attest that the information provided in this application is correct to the best of my knowledge as an authorized signer for this business.',
                  },
                  expressions: {
                    'props.disabled': () => {
                      return !(this.options.formState.openedDisclosure && this.options.formState.openedTerms);
                    },
                  },
                  wrappers: [],
                  validators: {
                    validation: [
                      {
                        name: 'requiredTrue',
                      },
                    ],
                  },
                },
              ],
            },
            {
              props: {
                label: 'What happens next?',
                expanded: true,
              },
              fieldGroup: [
                {
                  template: `
                  <p>
                    Once you select "Sign Up", you will be directed to the LINQ Pay Portal. Although you will not be able to process payments until your application is approved.
                    You will have the opportunity to familiarize yourself with your portal and what actions you can expect to be able to complete.
                  </p>
                  <p>If you have any questions about your application while it is pending approval, please contact us at <a target="_blank" href="mailto:linqpay@linq.com">linqpay@linq.com</a></p>
                  `,
                },
              ],
            },
          ],
        },
      ],
    },
  ];
}
