import { PasswordMaxLength, UsernameMaxLength } from './account';

export const Masks = {
  Zip5: '00000',
  Zip9: '00000-0000',
  Zip: '00000||00000-0000',
  Phone: '000-000-0000',
  Decimal2: 'separator.2', // 1,111.11
  SSN: 'XXX-XX-XXXX',
  EIN: 'XX-XXXXXXX',
  Email: 'A*@A*.SSS',
  Password: 'X'.repeat(PasswordMaxLength),
  Username: 'a'.repeat(UsernameMaxLength), // lowercase alphanumeric only
};
