<div class="landing">
  <div class="row">
    <div class="col-4">
      <img class="logo" src="/LINQ_Icon_SVG_FullColor.svg" alt="LINQ Logo" />
    </div>
    <div class="col-8 d-flex justify-content-center align-items-center">
      <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-title>Welcome to LINQ Merchant Application</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <button mat-raised-button routerLink="/application">Get Started</button>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
