import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Configuration, MerchantsApi, PostMerchantRequest, PostPlaidLinkRequest } from '@linq/pay-js';
import _ from 'lodash';
import { defer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LinqPayService {
  merchantsApi: MerchantsApi;

  constructor() {
    const config = new Configuration({
      basePath: environment.apiUrl,
    });
    this.merchantsApi = new MerchantsApi(config);
  }

  postMerchant(merchantApplication: PostMerchantRequest) {
    const postMerchantRequest = _.cloneDeep(merchantApplication);
    return defer(() => this.merchantsApi.postMerchant(postMerchantRequest).then((response) => response.data));
  }

  postDocument(documentId: string, file: File) {
    return defer(() => this.merchantsApi.postDocument(documentId, file).then((response) => response.data));
  }

  postPlaidLink(plaidTokenRequest: PostPlaidLinkRequest) {
    return defer(() => this.merchantsApi.postPlaidLink(plaidTokenRequest).then((response) => response.data));
  }
}
