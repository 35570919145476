/* tslint:disable */
/* eslint-disable */
/**
 * Payments API
 * # Introduction Manages onboarding of vendors and processing of payments  # Authentication The Payments API uses the OAuth2 client credentials grant to authorize service-to-service requests on the backend.  The caller should have only one application client that requests tokens for different audiences. Each API or audience that app client wishes to request tokens for requires an authorization in shared services IaC for Auth0.  If your application has not received Auth0 client credentials please refer to Architecture  Scopes are configured on client setup and all tokens requested by the client will have all scopes the client is entitled to.  The Token URL will change per environment.  - Dev - `https://dev.api.linqengineering.com/accounts/oauth/token`  - UAT - `https://uat.api.linqengineering.com/accounts/oauth/token`  - Production - `https://api.linqengineering.com/accounts/oauth/token`  In addition to your client credentials you also need to supply an audience value based on the environment. <br /> Valid Audiences include:      - Dev - `https://dev.api.linqengineering.com/payments`    - UAT - `https://uat.api.linqengineering.com/payments`    - Production - `https://api.linqengineering.com/payments`  Example Postman request to accounts API token endpoint:  ``` POST https://{{apiDomain}}.linqengineering.com/accounts/oauth/token ... raw -> JSON Body  {   \"clientId\": \"{{clientId}}\",   \"clientSecret\": \"{{clientSecret}}\",   \"audience\": \"https://{{apiDomain}}.linqengineering.com/payments\" } ```  See the Shared Services Postman collection for additional working examples. The returned access token should be added as a `Bearer` token authorization header.  API requests without an authorization header will fail.   <SecurityDefinitions />
 *
 * The version of the OpenAPI document: 0.0.30
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import axios from 'axios';
class AuthResponse {
  constructor(obj) {
    this.accessToken = obj.accessToken;
    this.expiresAt = Number(obj.expiresAt);
  }
}
class OAuth2Authenticator {
  constructor(configuration) {
    this.authResponse = null;
    this.configuration = configuration;
  }
  getToken() {
    return __awaiter(this, void 0, void 0, function* () {
      if (this.authResponse && this.authResponse.accessToken && new Date(this.authResponse.expiresAt * 1000) > new Date()) {
        return this.authResponse;
      }
      return axios.request({
        method: 'post',
        url: this.configuration.oAuthUrl,
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          grant_type: this.configuration.oAuthGrantType,
          clientId: this.configuration.oAuthClientId,
          clientSecret: this.configuration.oAuthClientSecret,
          audience: this.configuration.oAuthAudience
        })
      }).then(response => {
        this.authResponse = new AuthResponse(response.data);
        return this.authResponse;
      });
    });
  }
  authenticate() {
    return __awaiter(this, void 0, void 0, function* () {
      const authResponse = yield this.getToken();
      return authResponse.accessToken;
    });
  }
}
export const LinqPayEnvironments = {
  "dev": "https://dev.services.linq.com/payments",
  "uat": "https://uat.services.linq.com/payments",
  "staging": "https://staging.services.linq.com/payments",
  "prod": "https://services.linq.com/payments"
};
export class Configuration {
  constructor(param = {}) {
    this.apiKey = param.apiKey;
    this.username = param.username;
    this.password = param.password;
    this.basePath = param.basePath;
    this.serverIndex = param.serverIndex;
    this.baseOptions = param.baseOptions;
    this.formDataCtor = param.formDataCtor;
    this.oAuthClientId = param.oAuthClientId;
    this.oAuthClientSecret = param.oAuthClientSecret;
    this.oAuthAudience = param.oAuthAudience;
    this.oAuthGrantType = param.oAuthGrantType || 'client_credentials';
    this.oAuthUrl = param.oAuthUrl;
    const urlConfigurations = {
      "dev": "https://dev.services.linq.com/payments",
      "uat": "https://uat.services.linq.com/payments",
      "staging": "https://staging.services.linq.com/payments",
      "prod": "https://services.linq.com/payments"
    };
    const environment = Object.keys(urlConfigurations).find(key => urlConfigurations[key] === this.basePath);
    if (!environment) {
      console.warn("The provided base path does not match any known environment.");
      console.warn(`Base Paths: ${JSON.stringify(urlConfigurations, null, 2)}`);
    }
    const defaultDevConfiguration = {
      oAuthUrl: "https://dev.api.linqengineering.com/accounts/oauth/token",
      oAuthAudience: "https://dev.api.linqengineering.com/payments"
    };
    const defaultUatConfiguration = {
      oAuthUrl: "https://uat.api.linqengineering.com/accounts/oauth/token",
      oAuthAudience: "https://uat.api.linqengineering.com/payments"
    };
    const defaultProdConfiguration = {
      oAuthUrl: "https://api.linqengineering.com/accounts/oauth/token",
      oAuthAudience: "https://api.linqengineering.com/payments"
    };
    const defaultStagingConfiguration = {
      oAuthUrl: "https://staging.api.linqengineering.com/accounts/oauth/token",
      oAuthAudience: "https://staging.api.linqengineering.com/payments"
    };
    const defaultConfigs = {
      "dev": defaultDevConfiguration,
      "uat": defaultUatConfiguration,
      "staging": defaultStagingConfiguration,
      "prod": defaultProdConfiguration
    };
    if (environment) {
      const configForEnvironment = defaultConfigs[environment];
      if (!this.oAuthUrl) {
        this.oAuthUrl = configForEnvironment.oAuthUrl;
      }
      if (!this.oAuthAudience) {
        this.oAuthAudience = configForEnvironment.oAuthAudience;
      }
    }
    if (this.oAuthClientId && this.oAuthClientSecret && this.oAuthAudience && this.oAuthGrantType && this.oAuthUrl) {
      const authenticator = new OAuth2Authenticator(this);
      this.accessToken = () => {
        return authenticator.authenticate();
      };
    } else {
      this.accessToken = param.accessToken;
    }
  }
  /**
   * Check if the given MIME is a JSON MIME.
   * JSON MIME examples:
   *   application/json
   *   application/json; charset=UTF8
   *   APPLICATION/JSON
   *   application/vnd.company+json
   * @param mime - MIME (Multipurpose Internet Mail Extensions)
   * @return True if the given MIME is JSON, false otherwise.
   */
  isJsonMime(mime) {
    const jsonMime = new RegExp('^(application\/json|[^;/ \t]+\/[^;/ \t]+[+]json)[ \t]*(;.*)?$', 'i');
    return mime !== null && (jsonMime.test(mime) || mime.toLowerCase() === 'application/json-patch+json');
  }
}