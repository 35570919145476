<div class="merchant-application-container">
  <h4>
    <strong>Welcome! Let's get started!</strong>
  </h4>
  <div class="row">
    <div
      class="col-9"
      [ngClass]="{
        'col-12': errorMessages.size === 0,
      }"
    >
      <linq-formly
        [model]="model"
        [fields]="fields"
        [options]="options"
        [form]="form"
        (submitted)="submit()"
        (touchedErrorMessages)="onErrorMessages($event)"
      >
      </linq-formly>
    </div>
    @if (errorMessages.size > 0) {
      <div class="col-3">
        <mat-card class="error-card">
          <mat-card-header class="error-card__header">
            <mat-card-title-group>
              <mat-card-title class="d-flex align-items-center">
                <mat-icon> error </mat-icon> <span class="linq-ml-1">Issue Detected</span>
              </mat-card-title>
            </mat-card-title-group>
          </mat-card-header>
          <mat-card-content>
            <p>Merchant application contains the following errors:</p>
            <ul class="error-list">
              @for (errorMessage of errorMessages | keyvalue; track errorMessage.key) {
                <li class="error-list__list-item">
                  <button mat-button (click)="onErrorMessageClick(errorMessage.value)">
                    <p class="error-list__error-message">
                      {{ errorMessage.value.errorMessage }}
                    </p>
                  </button>
                </li>
              }
            </ul>
          </mat-card-content>
        </mat-card>
      </div>
    }
  </div>
</div>
@if (loading) {
  <div class="loading-spinner-container">
    <mat-spinner></mat-spinner>
  </div>
}
