import { Routes } from '@angular/router';
import { MerchantApplicationComponent, LandingComponent, SubmittedComponent } from '@app/routes';

export const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
  },
  {
    path: 'application',
    component: MerchantApplicationComponent,
  },
  {
    path: 'submitted',
    component: SubmittedComponent,
  },
];
